<template>
  <component
    :is="currentDialogComponent"
    v-bind="{
      isOpen,
      ...(currentDialog ? currentDialog?.props : {}),
    }"
    @close="onClose()"
  />

  <component
    :is="priorityDialogComponent"
    v-bind="{
      isOpen: isPriorityOpen,
      zIndex: 200,
      ...(priorityDialog ? priorityDialog?.props : {}),
    }"
    @close="onPriorityClose()"
  />
</template>

<script setup lang="ts">
const { currentDialog, priorityDialog } = useDialogStore();

const isOpen = ref(false);

const isPriorityOpen = ref(false);

const currentDialogComponent = computed(() =>
  currentDialog.value ? resolveComponent(currentDialog.value?.component) : null,
);

const priorityDialogComponent = computed(() =>
  priorityDialog.value
    ? resolveComponent(priorityDialog.value?.component)
    : null,
);

const onClose = () => {
  isOpen.value = false;
};

const onPriorityClose = () => {
  isPriorityOpen.value = false;

  if (currentDialogComponent.value) {
    isOpen.value = true;
  }
};

watch(
  () => currentDialog.value?.props,
  async (props) => {
    isOpen.value = false;

    if (props?.id) {
      await pause(500);

      isOpen.value = true;
    }
  },
  { immediate: true },
);

watch(
  () => priorityDialog.value?.props,
  async (props) => {
    isPriorityOpen.value = false;

    if (props?.id) {
      await pause(300);

      // TODO:  This breaks adding publications during a resource application. Either fix or remove
      // isOpen.value = false;

      isPriorityOpen.value = true;
    }
  },
  { immediate: true },
);
</script>

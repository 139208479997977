import type { EventBusKey } from '@vueuse/core';
import type { AddedPublication } from '@/types/publication';
import type { FundingDecision } from '@/types/research';

export const publicationAddedKey: EventBusKey<AddedPublication[]> =
  Symbol('publication-added');

export const fundingDecisionsAddedKey: EventBusKey<{
  selections: FundingDecision[];
  id: string;
}> = Symbol('funding-decisions-added');

export const projectFundingDecisionsAddedKey: EventBusKey<void> = Symbol(
  'project-funding-decisions-added',
);

export const maintenanceDialogClosedKey: EventBusKey<void> = Symbol(
  'maintenance-dialog-closed',
);

export const mailBoxMessageDeletedKey: EventBusKey<void> = Symbol(
  'mailbox-message-deleted',
);

export const loginMethodConfirmationCancelKey: EventBusKey<void> = Symbol(
  'login-method-confirmation-cancel',
);

export const resourceApplicationApprovedKey: EventBusKey<void> = Symbol(
  'resource-application-approved',
);

export const displayTypeChangedKey: EventBusKey<void> = Symbol(
  'display-type-changed',
);

export const idaAdminQuotaDialogClosedKey: EventBusKey<void> =
  Symbol('ida-dialog-closed');

export const reppuTokenAddedKey: EventBusKey<void> = Symbol(
  'reppi-api-token-created',
);

export const projectDetailsEditedKey: EventBusKey<void> = Symbol(
  'project-details-edited',
);

export const projectPresentationModifiedKey: EventBusKey<void> = Symbol(
  'project-presentation-modified',
);

export const projectMembershipModifiedKey: EventBusKey<void> = Symbol(
  'project-membership-modified',
);

export const projectMembershipRequestModifiedKey: EventBusKey<void> = Symbol(
  'project-membership-request-modified',
);

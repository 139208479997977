import type { DirectiveBinding } from 'vue';

type TooltipOptions = {
  text: string;
  position: 'top' | 'bottom' | 'left' | 'right';
};

type TooltipModifiers = {
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
};

const getPositionClass = (modifiers: TooltipModifiers) =>
  Object.entries(modifiers)
    .filter(([, value]) => value)
    .map(([key]) => key)
    .join('-') || ['top'];

const TooltipDirective = (
  el: HTMLElement,
  binding: DirectiveBinding<TooltipOptions | string>,
) => {
  el.setAttribute(
    'data-tooltip',
    (binding.value as TooltipOptions).text || (binding.value as string),
  );

  el.classList.add('with-tooltip');

  const position =
    (binding.value as TooltipOptions).position ||
    getPositionClass(binding.modifiers);

  el.classList.add(`tooltip--${position}`);
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('tooltip', TooltipDirective);
});

/**
 * Returns the object property value with dot notation string
 *
 * @param obj The source object
 * @param path The path to the source object property in dot notation
 *
 * @returns The requested property value || undefined
 *
 * @example
 * const source = {
 *   active: true,
 *   status: {
 *     code: 200,
 *     message: 'ok',
 *   },
 * };
 * const path = 'status.code';
 * const result = getDescendantProp(source, path); // => 200
 */
export const getDescendantProp = <T>(obj: Record<string, any>, path: string) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj) as T;

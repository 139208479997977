import {
  mdiBellAlertOutline,
  mdiClipboardTextClockOutline,
  mdiPowerPlug,
  mdiPowerPlugOffOutline,
} from '@mdi/js';
import { CToastType, type CSelectItem } from '@cscfi/csc-ui';
import { gql } from 'graphql-tag';
import type {
  UpdateIdaQuotaAdminBody,
  UpdateIdaQuotaBody,
} from '../schemas/quota.schema';
import type { ProcessIdaApplicationBody } from '../schemas/application.schema';
import type {
  AcceptServiceTermsBody,
  AddIdaServiceBody,
} from '../schemas/service.schema';
import type { PendingTerms } from '../types/service';
import type {
  Service,
  MahtiQuotaDefaults,
  MahtiQuotaResult,
  IdaApplicationsResponse,
  IdaApplication,
  IdaOrganization,
  IdaApplicationStatusResponse,
  OrganizationIdaProjects,
  MahtiQuotaResponse,
  MahtiQuotaDefaultsResponse,
  ProjectServiceProfile,
  IdaAdminQuotaChangeWSResponse,
} from '@/types/service';
import { services, lumiServices } from '@/data';
import {
  type WorkflowResponse,
  type ServiceProfileApplicationStatus,
  type GenericWorkflowResponse,
  DataResponse,
} from '@/types';
import type {
  OrganizationBillingUnits,
  OrganizationLumiProject,
  OrganizationProject,
  OrganizationStats,
} from '@/types/organization';
import { idaAdminQuotaDialogClosedKey } from '@/eventKeys';
import type {
  AllasQuotaWorkflowParameters,
  MahtiQuotaWorkflowParameters,
  PuhtiQuotaWorkflowParameters,
} from '~/layers/project/schemas/quota.schema';
import { getServiceNames } from '~/data/services';

export const useServiceProfileStore = defineComposableStore(
  'serviceProfile',
  () => {
    const projectServiceProfiles = ref<ProjectServiceProfile[]>();

    const puhtiQuotaDefaults = ref<MahtiQuotaDefaults>();

    const mahtiQuotaDefaults = ref<MahtiQuotaDefaults>();

    const puhtiQuota = ref<MahtiQuotaResult[]>([]);

    const mahtiQuota = ref<MahtiQuotaResult[]>([]);

    const idaApplications = ref<IdaApplication[]>([]);

    const idaOrganizations = ref<IdaOrganization[]>([]);

    const organizationIdaProjects = ref<OrganizationIdaProjects[]>([]);

    const organizationBillingUnits = ref<OrganizationBillingUnits[]>([]);

    const organizationProjects = ref<OrganizationProject[]>([]);

    const organizationLumiProjects = ref<OrganizationLumiProject[]>([]);

    const currentlyAddedServiceIds = ref<string[]>([]);

    const pendingTerms = ref<PendingTerms>({} as PendingTerms);

    const { addNotification } = useNotificationStore();

    const { startLoading, endLoading, isLoading, getLoadingMessage } =
      useLoadingStore();

    const { hasRequiredRoles } = useProfileStore();

    const { project, currentProjectNumber } = useProjectStore();

    const route = useRoute();

    const idaServiceStatus = ref('');

    const mahtiQuotaApplicationStatus = ref('');

    const puhtiQuotaApplicationStatus = ref('');

    const idaApplication = ref<IdaApplicationStatusResponse>();

    const selectedIdaOrganization = ref<CSelectItem>();

    const { emit } = useEventBus(idaAdminQuotaDialogClosedKey);

    const getOrganizationIdaProjects = async (
      organizationId: string,
    ): Promise<OrganizationIdaProjects[]> => {
      startLoading('getOrganizationIdaProjects');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationIdaProjectsQuery = gql`
        query OrganizationIdaProjects($organizationId: String!) {
          organizationIdaProjects(organizationId: $organizationId) {
            cscprjnum
            total_files
            used_gb
            frozen_gb
            user_count
            title
            project_status
            granted_gb
            granted_extra_gb
            pi_email
            pi_logindisabled
            cscorgidastorquota
            cscprjopendate
            cscprjcloseddate
            dataset_byte_size
            published_datasets
            published_percent
            last_data_change
            cscprjprocessespersonaldata
          }
        }
      `;

      await query(organizationIdaProjectsQuery, { organizationId });

      if (error.value) {
        endLoading('getOrganizationIdaProjects');

        return [];
      }

      organizationIdaProjects.value = gqlResponse.value?.organizationIdaProjects
        ? gqlResponse.value.organizationIdaProjects.map(
            (item: OrganizationIdaProjects) => ({
              ...item,
              usage: (+item.used_gb / +item.granted_gb) * 100,
              actions: item.cscprjnum,
            }),
          )
        : ([] as OrganizationIdaProjects[]);

      endLoading('getOrganizationIdaProjects');

      return organizationIdaProjects.value;
    };

    const getOrganizationUsers = async (
      organizationId: string,
      year: string,
    ) => {
      startLoading('getOrganizationUsers');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationUsersQuery = gql`
        query organizationUsers($organizationId: String!, $year: String!) {
          organizationUsers(organizationId: $organizationId, year: $year) {
            fullname_user
            cscusertype
            uidnumber
            cscsciencearea
            science_en_user
          }
        }
      `;

      await query(organizationUsersQuery, { organizationId, year });

      if (error.value) {
        endLoading('getOrganizationUsers');

        return [];
      }

      endLoading('getOrganizationUsers');

      return gqlResponse.value?.organizationUsers;
    };

    const getOrganizationProjects = async (
      organizationId: string,
      year: string,
    ) => {
      startLoading('getOrganizationProjects');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationProjectsQuery = gql`
        query organizationProjects($organizationId: String!, $year: String!) {
          organizationProjects(organizationId: $organizationId, year: $year) {
            project_number
            title
            status
            science_area
            cscsrpcompprjusedcompbus
            cscsrpcompprjallocatedcompbus
            processes_personal_data
            gdpr_contract_cn
            project_manager
            project_manager_login_disabled
            nas
            allas
            epouta
            mahti
            mahti_lustre
            mahti_gpu
            mahti_cpu
            pouta
            puhti
            puhti_lustre
            puhti_cpu
            puhti_gpu
            rahti
            sddesktop
            taito
            taitoshell
            sisu
            total
          }
        }
      `;

      await query(organizationProjectsQuery, { organizationId, year });

      if (error.value) {
        endLoading('getOrganizationProjects');

        return;
      }

      endLoading('getOrganizationProjects');

      organizationProjects.value =
        gqlResponse.value?.organizationProjects || [];
    };

    const getOrganizationLumiProjects = async (
      organizationId: string,
      year: string,
    ) => {
      startLoading('getOrganizationLumiProjects');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationLumiProjectsQuery = gql`
        query organizationLumiProjects(
          $year: String!
          $organizationId: String!
        ) {
          organizationLumiProjects(
            year: $year
            organizationId: $organizationId
          ) {
            cpu_hours
            gpu_hours
            weighted_tb_hours
            project_number
            qpu_seconds
            end_date
            project_manager
            project_manager_login_disabled
            start_date
            status
            title
          }
        }
      `;

      await query(organizationLumiProjectsQuery, { organizationId, year });

      if (error.value) {
        endLoading('getOrganizationLumiProjects');

        return;
      }

      endLoading('getOrganizationLumiProjects');

      organizationLumiProjects.value = (gqlResponse.value
        ?.organizationLumiProjects || []) as OrganizationLumiProject[];
    };

    const getOrganizationStats = async (
      organizationId: string,
      year: string,
    ): Promise<OrganizationStats> => {
      startLoading('getOrganizationStats');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationStatsQuery = gql`
        query organizationStats($organizationId: String!, $year: String!) {
          organizationStats(organizationId: $organizationId, year: $year) {
            projectcount
            usercount
            activeprojectcount
          }
        }
      `;

      await query(organizationStatsQuery, { organizationId, year });

      if (error.value) {
        endLoading('getOrganizationStats');

        return {
          usercount: 0,
          projectcount: 0,
          activeprojectcount: 0,
        };
      }

      endLoading('getOrganizationStats');

      return gqlResponse.value?.organizationStats;
    };

    const getOrganizationBillingUnits = async (
      organizationId: string,
      year: string,
    ) => {
      startLoading('getOrganizationBillingUnits');

      const { response: gqlResponse, query, error } = useGqlApi();

      const organizationBillingUnitsQuery = gql`
        query OrganizationBillingUnits(
          $organizationId: String!
          $year: String!
        ) {
          organizationBillingUnits(
            organizationId: $organizationId
            year: $year
          ) {
            project_number
            service
            year
            month
            bus
            status
          }
        }
      `;

      await query(organizationBillingUnitsQuery, { organizationId, year });

      if (error.value) {
        endLoading('getOrganizationBillingUnits');

        return;
      }

      endLoading('getOrganizationBillingUnits');

      organizationBillingUnits.value =
        gqlResponse.value?.organizationBillingUnits;
    };

    const getPendingTerms = async () => {
      const { data } = await api.get<DataResponse<PendingTerms>>(
        '/api/project/service-profiles/pending',
      );

      pendingTerms.value = data || ({} as PendingTerms);
    };

    const getIdaServiceStatus = async (projectNumber: string) => {
      const { data } = await api.get<DataResponse<{ status: string }>>(
        `/api/service/ida/status/${projectNumber}`,
      );

      idaServiceStatus.value = data?.status || '';
    };

    const getIdaApplicationStatus = async (
      projectNumber: string,
      applicationId: number,
    ) => {
      startLoading('idaApplication');

      const { data } = await api.get<
        DataResponse<IdaApplicationStatusResponse>
      >(
        `/api/service/ida/status/application/${projectNumber}/${applicationId}`,
      );

      if (data?.id) {
        idaApplication.value = data;
      }

      endLoading('idaApplication');
    };

    const getPuhtiQuotaDefaults = async () => {
      startLoading('puhtiQuotaDefaults');

      const { projectNumber } = route.params;

      const { data } = await api.get<MahtiQuotaDefaultsResponse>(
        `/api/project/quota/default-values/${projectNumber}/puhti`,
      );

      puhtiQuotaDefaults.value = data || undefined;

      endLoading('puhtiQuotaDefaults');
    };

    const getMahtiQuotaDefaults = async () => {
      startLoading('mahtiQuotaDefaults');

      const { projectNumber } = route.params;

      const { data } = await api.get<MahtiQuotaDefaultsResponse>(
        `/api/project/quota/default-values/${projectNumber}/mahti`,
      );

      mahtiQuotaDefaults.value = data || undefined;

      endLoading('mahtiQuotaDefaults');
    };

    const getPuhtiQuotaApplicationStatus = async () => {
      startLoading('getPuhtiQuotaApplicationStatus');

      const { projectNumber } = route.params;

      const { data } = await api.get<ServiceProfileApplicationStatus>(
        `/api/project/quota/status/puhti/${projectNumber}`,
      );

      endLoading('getPuhtiQuotaApplicationStatus');

      puhtiQuotaApplicationStatus.value = data.status || '';

      return puhtiQuotaApplicationStatus.value;
    };

    const getMahtiQuotaApplicationStatus = async () => {
      startLoading('getMahtiQuotaApplicationStatus');

      const { projectNumber } = route.params;

      const { data } = await api.get<ServiceProfileApplicationStatus>(
        `/api/project/quota/status/mahti/${projectNumber}`,
      );

      endLoading('getMahtiQuotaApplicationStatus');

      mahtiQuotaApplicationStatus.value = data.status || '';

      return data.status || '';
    };

    const getAllasQuotaApplicationStatus = async () => {
      startLoading('getAllasQuotaApplicationStatus');

      const { projectNumber } = route.params;

      const { data } = await api.get<ServiceProfileApplicationStatus>(
        `/api/project/quota/status/allas/${projectNumber}`,
      );

      endLoading('getAllasQuotaApplicationStatus');

      return data || '';
    };

    const getPuhtiQuota = async () => {
      startLoading('puhtiQuota');

      const { projectNumber } = route.params;

      const { data } = await api.get<MahtiQuotaResponse>(
        `/api/project/quota/${projectNumber}/puhti`,
      );

      puhtiQuota.value = data || [];

      endLoading('puhtiQuota');
    };

    const getMahtiQuota = async () => {
      startLoading('mahtiQuota');

      const { projectNumber } = route.params;

      const { data } = await api.get<MahtiQuotaResponse>(
        `/api/project/quota/${projectNumber}/mahti`,
      );

      mahtiQuota.value = data || [];

      endLoading('mahtiQuota');
    };

    const applyForIdaQuotaChange = async (size: number) => {
      startLoading('idaQuotaChange');

      const data = await api.put<UpdateIdaQuotaBody, WorkflowResponse>(
        '/api/service/ida/quota',
        {
          size,
          projectNumber: project.value?.CSCPrjNum,
        },
      );

      await getIdaServiceStatus(project.value?.CSCPrjNum);

      const { getProjectStats } = useResourceUsageStore();

      await getProjectStats(['idaUsage']);

      endLoading('idaQuotaChange');

      return data;
    };

    const handleIdaAdminQuotaChangeResponse = async (
      params: IdaAdminQuotaChangeWSResponse,
    ) => {
      if (params.status !== 'finished') return;

      if (params.data.ApprovalStatus !== 'Approved') {
        addNotification(`idaAdminQuotaChangeError`, {
          message: 'Modifying quota failed',
          type: CToastType.Error,
        });

        endLoading('idaAdminQuotaChange');

        return;
      }

      const cn = getCnFromDn(
        (selectedIdaOrganization.value?.value as string) || '',
      );

      await getOrganizationIdaProjects(cn);
      await getIDAApplications(selectedIdaOrganization.value?.value as string);

      addNotification(`idaAdminQuotaChangeSuccess`, {
        message: 'Quota successfully changed',
        type: CToastType.Success,
      });

      endLoading('idaAdminQuotaChange');

      emit();
    };

    const idaAdminQuotaChange = async (params: {
      grantedStorage: string;
      projectNumber: string;
    }) => {
      startLoading('idaAdminQuotaChange');

      const { subscribe } = useSocket<IdaAdminQuotaChangeWSResponse>(
        handleIdaAdminQuotaChangeResponse,
      );

      const { data } = await api.put<
        UpdateIdaQuotaAdminBody,
        GenericWorkflowResponse
      >('/api/service/ida/quota/admin', params);

      if (data.reqId) {
        subscribe(data.reqId);
      }

      return data;
    };

    const idaApplicationApproval = async (
      payload: ProcessIdaApplicationBody,
    ) => {
      startLoading('idaApproval');

      const response = await api.post<
        ProcessIdaApplicationBody,
        GenericWorkflowResponse
      >('/api/service/ida/application/process', payload);

      await pause(2500);
      await getIDAApplications();

      endLoading('idaApproval');

      return response;
    };

    /**
     * Wait for quota change workflow to finish
     *
     * @param requestId workflow request id
     */
    const waitForQuotaChange = (requestId: string) => {
      const { subscribe } = useSocket<{
        status: string;
        data: { CSCServiceID: string };
      }>(async (response) => {
        if (response.status === 'finished') {
          const loader =
            response.data.CSCServiceID === 'PUHTIQUOTA'
              ? 'setPuhtiQuota'
              : 'setMahtiQuota';

          await pause(3500);

          const toasts = document.querySelector(
            '#toasts',
          ) as HTMLCToastsElement;

          toasts?.removeToast('submittingQuotaChange');

          endLoading(loader);

          addNotification(loader, {
            message: 'Quota application successfully submitted',
            type: CToastType.Success,
          });

          if (response.data.CSCServiceID === 'PUHTIQUOTA') {
            await getPuhtiQuota();
            await getPuhtiQuotaApplicationStatus();

            return;
          }

          await getMahtiQuota();
          await getMahtiQuotaApplicationStatus();
        }
      });

      addNotification('submittingQuotaChange', {
        indeterminate: true,
        title: 'Submitting quota change',
        message: 'Please wait',
        type: CToastType.Info,
        id: 'submittingQuotaChange',
        progress: true,
      });

      subscribe(requestId);
    };

    const setMahtiQuota = async (payload: MahtiQuotaWorkflowParameters) => {
      startLoading('setMahtiQuota');

      const { success, data } = await api.post<
        MahtiQuotaWorkflowParameters,
        GenericWorkflowResponse
      >('/api/project/quota/mahti', payload);

      if (!success) {
        endLoading('setMahtiQuota');

        return;
      }

      waitForQuotaChange(data.reqId || '');

      return data;
    };

    const setPuhtiQuota = async (payload: PuhtiQuotaWorkflowParameters) => {
      startLoading('setPuhtiQuota');

      const { success, data } = await api.post<
        PuhtiQuotaWorkflowParameters,
        GenericWorkflowResponse
      >('/api/project/quota/puhti', payload);

      if (!success) {
        endLoading('setPuhtiQuota');

        return;
      }

      waitForQuotaChange(data.reqId || '');

      return data;
    };

    const setAllasQuota = async (payload: AllasQuotaWorkflowParameters) => {
      startLoading('setAllasQuota');

      const { data } = await api.post<
        AllasQuotaWorkflowParameters,
        GenericWorkflowResponse
      >('/api/project/quota/allas', payload);

      return data;
    };

    const getProjectServiceProfiles = async (
      params: {
        startLoader?: boolean;
        endLoader?: boolean;
        projectNumber?: string;
        loaderName?: string;
        getIdaStatus?: boolean;
      } = {},
    ) => {
      const options = {
        startLoader: true,
        endLoader: true,
        projectNumber: '',
        loaderName: 'serviceProfiles',
        getIdaStatus: true,
        ...params,
      };

      if (options.startLoader) {
        startLoading(options.loaderName);
      }

      const { clearExpiredSessions } = useSessionStore();

      clearExpiredSessions();

      const response = await api.get<DataResponse<ProjectServiceProfile[]>>(
        `/api/project/service-profiles/${options.projectNumber || currentProjectNumber.value}`,
      );

      projectServiceProfiles.value = response?.data;

      if (options.getIdaStatus)
        await getIdaServiceStatus(
          options.projectNumber || currentProjectNumber.value,
        );

      if (options.endLoader) {
        endLoading(options.loaderName);
      }
    };

    const getIDAApplications = async (organizationDn?: string) => {
      startLoading('idaApplications');

      const url = `/api/service/ida/application${
        organizationDn ? `/${organizationDn}` : ''
      }`;

      const { success, data } = await api.get<IdaApplicationsResponse>(url);

      if (success) {
        idaApplications.value = data;
      }

      endLoading('idaApplications');
    };

    const getIDAOrganizations = async () => {
      startLoading('idaOrganizations');

      const { data } = await api.get<DataResponse<IdaOrganization[]>>(
        '/api/service/ida/organizations',
      );

      idaOrganizations.value = data || [];

      endLoading('idaOrganizations');
    };

    const applyForMahtiPartition = async () => {
      startLoading('mahtiPartition');

      const payload = {
        projectNumber: route.params.projectNumber as string,
      };

      const data = await api.post<
        { projectNumber: string },
        { status: number }
      >('/api/service/large-partition/trial', payload);

      await pause(2500);

      await getMahtiQuota();

      endLoading('mahtiPartition');

      return data;
    };

    const pollCount = ref(0);

    const serviceNames = getServiceNames();

    const pollServices = async (serviceIds: string[]) => {
      pollCount.value += 1;

      await getProjectServiceProfiles({ startLoader: false, endLoader: false });

      const enabledServiceIds = (projectServiceProfiles.value || [])
        .filter((service) => !!service.termsAccepted)
        .map((service) => service.profileName.toLowerCase());

      if (
        !serviceIds.every((id) => enabledServiceIds.includes(id)) &&
        pollCount.value < 20
      ) {
        await pause(1000);

        await pollServices(serviceIds);

        return;
      }

      if (
        currentlyAddedServiceIds.value.every((id) =>
          enabledServiceIds.includes(id),
        )
      ) {
        if (isLoading('serviceProfiles')) {
          addNotification('serviceProfiles', {
            title: `${formatList(
              enabledServiceIds.map((serviceId) => serviceNames[serviceId]),
            )} ${serviceIds.length > 1 ? 'have' : 'has'} been enabled`,
            message: `It may take up to 30 - 60 minutes for the changes to take effect.`,
            type: CToastType.Success,
          });
        }

        endLoading('serviceProfiles');

        currentlyAddedServiceIds.value = [];
      }

      pollCount.value = 0;
    };

    const serviceMessages = ref<
      Record<
        string,
        { serviceId: string; serviceName: string; status: 'success' | 'error' }
      >
    >({});

    const handleServiceActivationResponse = async (data: {
      status: string;
      requestId: string;
    }) => {
      if (data.status !== 'finished') return;

      await pollServices(
        Object.values(serviceMessages.value).map((service) =>
          service.serviceId.toLowerCase(),
        ),
      );

      const { serviceName, status } = serviceMessages.value[data.requestId];

      if (status === 'error') {
        addNotification(`serviceEnabled:${serviceName}`, {
          message: `Enabling ${serviceName} failed`,
          type: CToastType.Error,
        });

        return;
      }

      const { createSession } = useSessionStore();

      createSession({
        id: `service-activation--${serviceName}`,
        state: {
          serviceName,
          projectNumber: project.value?.CSCPrjNum,
          timestamp:
            Date.now() + (serviceName === 'Puhti' ? 60 : 30) * 60 * 1000,
        },
      });
    };

    const enableServices = async (serviceProfiles: Service[]) => {
      startLoading('serviceProfiles', 'Enabling services');

      currentlyAddedServiceIds.value = serviceProfiles.map(
        (service) => service.serviceId,
      );

      serviceMessages.value = {};

      const { subscribe } = useSocket<{ status: string; requestId: string }>(
        handleServiceActivationResponse,
      );

      for await (const service of serviceProfiles) {
        const ou = ['dbaas', 'sd-desktop', 'allas', 'sd-connect'].includes(
          service.serviceName.toLowerCase(),
        )
          ? 'Storage'
          : 'Computing';
        const serviceDN = `cn=${service.serviceName},ou=${ou},ou=External,ou=Services,o=CSC`;

        const payload = {
          serviceDN,
          projectNumber: project.value?.CSCPrjNum,
        };

        const response = await api.post<
          typeof payload,
          GenericWorkflowResponse
        >('/api/service/enable', payload);

        if (response.data?.reqId) {
          serviceMessages.value[response.data.reqId] = {
            serviceId: service.serviceId,
            serviceName: service.serviceName,
            status: response.data.reqId ? 'success' : 'error',
          };

          subscribe(response.data.reqId);
        }
      }
    };

    const acceptService = async (service: Service) => {
      startLoading('serviceProfiles', 'Accepting service terms');

      const payload: AcceptServiceTermsBody = {
        serviceProfileCN: service.serviceCn || '',
        projectNumber: currentProjectNumber.value,
      };

      const data = await api.post<AcceptServiceTermsBody, WorkflowResponse>(
        '/api/service/accept-terms',
        payload,
      );

      await pollServices([service.serviceId]);

      return data;
    };

    const enableServiceIda = async (
      params: Omit<AddIdaServiceBody, 'projectNumber'>,
    ) => {
      startLoading('enableServiceIda');

      const payload: AddIdaServiceBody = {
        ...params,
        projectNumber: currentProjectNumber.value,
      };

      const data = await api.post<AddIdaServiceBody, { status: number }>(
        '/api/service/ida/enable',
        payload,
      );

      await pause(8000);

      addNotification(`applyForIda`, {
        title: 'IDA application successfully sent',
        message: 'Your application will be manually reviewed',
        type: CToastType.Success,
      });

      await getProjectServiceProfiles({ startLoader: false });

      endLoading('enableServiceIda');

      return data;
    };

    const serviceProfiles = computed(() => {
      return (
        project.value?.CSCPrjScope === 'lumi-okm' ? lumiServices : services
      )
        .filter((service) => {
          const isAllowed = service.requiredRoles
            ? hasRequiredRoles(service.requiredRoles)
            : true;

          const isIncluded = !!service?.includedOnlyInProjectTypes?.includes(
            project.value.projectType,
          );

          if (service?.includedOnlyInProjectTypes?.length) {
            return isIncluded;
          }

          const isExcluded = !!service?.excludedInProjectTypes?.includes(
            project.value.projectType,
          );

          return isAllowed && !isExcluded;
        })
        .map((service) => {
          let icon = mdiPowerPlugOffOutline;

          const projectServiceProfile = projectServiceProfiles.value?.find(
            (p) =>
              p.profileName.toLowerCase() === service.serviceId.toLowerCase(),
          );

          const isEnabled = !!projectServiceProfile;

          const isAccepted = !!projectServiceProfile?.termsAccepted;

          if (isEnabled) {
            icon = isAccepted ? mdiPowerPlug : mdiBellAlertOutline;
          }

          if (
            service.serviceId === 'ida01' &&
            idaServiceStatus.value === 'pending'
          ) {
            icon = mdiClipboardTextClockOutline;
          }

          return {
            ...service,
            accessGranted: projectServiceProfile?.startDate
              ? formatDate(projectServiceProfile.startDate)
              : false,
            accessExpires: projectServiceProfile?.endDate
              ? formatDate(projectServiceProfile.endDate)
              : undefined,
            isAccepted,
            isEnabled,
            isPending:
              ['lumi01', 'helmi01', 'helmi02'].includes(
                service.serviceId.toLowerCase(),
              ) && !isEnabled /* ||
              (!['lumi01', 'helmi01', 'helmi02'].includes(
                service.serviceId.toLowerCase(),
              ) &&
                !isEnabled &&
                !isAccepted) */,
            icon,
            profileName: projectServiceProfile?.profileName,
            serviceCn: projectServiceProfile?.cn,
          };
        })
        .filter((service) => {
          if (
            project.value?.CSCPrjScope === 'lumi-okm' &&
            ['helmi01', 'helmi02'].includes(service.serviceId) &&
            !service.isEnabled
          ) {
            return false;
          }

          return true;
        });
    });

    return {
      idaServiceStatus,
      idaApplication,
      puhtiQuotaDefaults,
      mahtiQuotaDefaults,
      mahtiQuota,
      puhtiQuota,
      serviceProfiles,
      idaApplications,
      idaOrganizations,
      mahtiQuotaApplicationStatus,
      puhtiQuotaApplicationStatus,
      organizationIdaProjects,
      organizationLumiProjects,
      organizationProjects,
      organizationBillingUnits,
      projectServiceProfiles,
      selectedIdaOrganization,
      pendingTerms,
      applyForIdaQuotaChange,
      applyForMahtiPartition,
      idaAdminQuotaChange,
      getPuhtiQuotaDefaults,
      getMahtiQuotaDefaults,
      getPuhtiQuota,
      getMahtiQuota,
      enableServices,
      enableServiceIda,
      getLoadingMessage,
      acceptService,
      setAllasQuota,
      setMahtiQuota,
      setPuhtiQuota,
      getIDAApplications,
      getIDAOrganizations,
      getIdaApplicationStatus,
      idaApplicationApproval,
      getMahtiQuotaApplicationStatus,
      getAllasQuotaApplicationStatus,
      getPuhtiQuotaApplicationStatus,
      getOrganizationIdaProjects,
      getOrganizationBillingUnits,
      getOrganizationStats,
      getOrganizationUsers,
      getOrganizationLumiProjects,
      getOrganizationProjects,
      getProjectServiceProfiles,
      getPendingTerms,
      serviceMessages,
    };
  },
);

import { toDataURL } from 'qrcode';
import { CToastType } from '@cscfi/csc-ui';
import type { SanitizedProfile } from '../types/profile';
import type { ProcessProfileExtensionRequestPayload } from '../schemas/extensionRequest.schema';
import type {
  AccoutLinkingBody,
  ChangePasswordBody,
  ForgotPasswordBody,
  ResetPasswordBody,
  SetDefaultScienceAreaBody,
  UpdateProfileBody,
} from '../schemas/profile.schema';
import type {
  LoaRaiseResponse,
  Profile,
  MFASeedResponse,
  UpdateProfileResponse,
  MFAVerifyResponse,
  SshKeyPayload,
  ChangePasswordResponse,
  UserInfo,
  AccountExtensionRequest,
} from '@/types/profile';
import type {
  DataResponse,
  GenericWorkflowResponse,
  LoaWorkflowResponse,
  ProcessAccountExtensionWorkflowResponse,
} from '@/types';
import { getLDAPErrorMessage } from '@/utils/getLDAPErrorMessage';
import type { RegistrationWSResponse } from '@/types/registration';
import { Dialogs, type Role } from '@/types/enum';
import type {
  SendEstimatesToEmailPayload,
  SendFeedbackParams,
} from '~/layers/mailbox/schemas/feedback';
import type {
  HakaRegistrationBody,
  NonHakaRegistrationBody,
} from '~/layers/authentication/schemas/registration.schema';

export const useProfileStore = defineComposableStore('profile', () => {
  const profile = ref<SanitizedProfile>({} as SanitizedProfile);

  const userInfo = ref<UserInfo>({} as UserInfo);

  const userToken = ref<string>('');

  const showLinkingInfo = ref(false);

  const mfa = ref<{ success: boolean; secret: string; url: string }>();

  const { addNotification } = useNotificationStore();

  const { startLoading, endLoading } = useLoadingStore();

  const { getMessages } = useMailboxStore();

  const { addDialog } = useDialogStore();

  const setUserInfo = (params: { userInfo: UserInfo; userToken: string }) => {
    userInfo.value = params.userInfo;
    userToken.value = params.userToken;
  };

  const forgotPassword = async ({ username }: ForgotPasswordBody) => {
    startLoading('forgotPassword');

    const response = await api.post<
      ForgotPasswordBody,
      DataResponse<{ message?: string; reqId?: string; status?: number }>
    >('/api/authentication/password/forgot', {
      username,
    });

    endLoading('forgotPassword');

    return response;
  };

  const resetPassword = async (payload: ResetPasswordBody) => {
    startLoading('resetPassword');

    const response = await api.post<ResetPasswordBody, ChangePasswordResponse>(
      '/api/authentication/password/reset',
      payload,
    );

    endLoading('resetPassword');

    if (!response.success) {
      return {
        success: false,
        message: getLDAPErrorMessage(
          response.data?.code ?? response.data?.reason ?? '',
        ),
      };
    }

    addNotification(`changePasswordNotification`, {
      message: 'Password changed successfully',
      type: CToastType.Success,
    });

    return { success: true };
  };

  /**
   * Retrieve userinfo for CAM debugging when user has login issues
   */
  const getUserInfo = async (code: string) => {
    const url = `/api/authentication/userinfo?code=${code}`;

    const { data } = await api.get<DataResponse<UserInfo>>(url);

    if (data) {
      setUserInfo({
        userInfo: data,
        userToken: '',
      });
    }
  };

  /**
   * Clear registration state from local storage if profile exists
   */
  const clearRegistration = () => {
    if (profile.value?.mail) {
      localStorage.removeItem(`registration-completed-${profile.value?.mail}`);
    }

    if (profile.value?.sn) {
      localStorage.removeItem(
        `registration-completed-${profile.value?.sn.replace(/\s/g, '')}`,
      );
    }
  };

  /**
   * Get user profile details from IDM
   */
  const getProfile = async (subscribeToInbox = true) => {
    startLoading('profile');

    const { subscribe } = useSocket<{
      status: string;
    }>(
      (response) => {
        if (response.status === 'changed') {
          const { getProjects } = useProjectStore();

          getProjects();
          getProfile(false);
          getMessages();
        }
      },
      {
        type: 'INBOX',
      },
    );

    const { data } =
      await api.get<DataResponse<SanitizedProfile>>('/api/profile');

    profile.value = data || ({} as SanitizedProfile);

    if (profile.value?.cn && subscribeToInbox) {
      subscribe(profile.value.cn);
    }

    clearRegistration();

    endLoading('profile');
    endLoading('linkLoginMethod');
  };

  const updateProfileFields = async (fields: SetDefaultScienceAreaBody) => {
    startLoading('updateProfileFields');

    await api.put<UpdateProfileBody, UpdateProfileResponse>(
      '/api/profile',
      fields,
    );

    await pause(2500);

    await getProfile();

    endLoading('updateProfileFields');
  };

  /**
   * Update profile details to IDM
   */
  const updateProfile = async (userProfile: Profile) => {
    try {
      startLoading('updateProfile');

      const payload: UpdateProfileBody = {
        CSCScienceArea: userProfile.CSCScienceArea,
        mail: userProfile.mail,
        co: userProfile.co,
        schacCountryOfCitizenship: userProfile.schacCountryOfCitizenship,
        CSCUserCusAuxEmail: userProfile.CSCUserCusAuxEmail,
        givenName: userProfile.givenName,
        sn: userProfile.sn,
        title: userProfile.title,
        street: userProfile.street,
        postalCode: userProfile.postalCode,
        city: userProfile.city,
        mobile: userProfile.mobile,
        preferredLanguage: userProfile.preferredLanguage,
        gidNumber: userProfile.gidNumber,
      };

      const response = await api.put<UpdateProfileBody, UpdateProfileResponse>(
        '/api/profile',
        payload,
      );

      await pause(2500);

      await getProfile();

      endLoading('updateProfile');

      return response;
    } catch (error) {
      console.error(error);

      addNotification(`updateProfileNotification`, {
        message: 'Profile update failed',
        type: CToastType.Error,
      });

      endLoading('updateProfile');
    }
  };

  /**
   * Fetch a seed and generate a data url for Multi-factor authentication QR code
   */
  const getMFASeed = async () => {
    startLoading('getMFASeed');

    const { success, data } = await api.get<MFASeedResponse>(
      '/api/authentication/otp',
    );

    if (!success) {
      return;
    }

    const dataUrl = await toDataURL(data?.url || '');

    mfa.value = {
      secret: data?.secret || '',
      url: dataUrl,
      success: !!success,
    };

    endLoading('getMFASeed');
  };

  /**
   * Verify the provided token in backend + activate MFA
   */
  const verifyMFAToken = async (token: string, onClose: () => void) => {
    startLoading('verifyMFAToken');

    const { subscribe } = useSocket<{
      status: string;
    }>(async (response) => {
      if (response.status === 'finished') {
        await pause(3000);

        await getProfile();

        endLoading('verifyMFAToken');

        onClose();

        addNotification(`mfaNotification`, {
          message: 'Multi-factor authentication enabled',
          type: CToastType.Success,
        });

        mfa.value = undefined;
      }
    });

    const { success, data } = await api.post<
      { token: string },
      MFAVerifyResponse
    >('/api/authentication/otp/verify', { token });

    if (!success || !data?.verified) {
      endLoading('verifyMFAToken');

      addNotification(`mfaNotification`, {
        message: 'Enabling the multi-factor authentication failed',
        type: CToastType.Error,
      });

      return;
    }

    if (data?.reqId) {
      subscribe(data.reqId);
    }
  };

  const disableMFA = async () => {
    startLoading('disableMFA');

    const { subscribe } = useSocket<{
      status: string;
    }>(async (response) => {
      if (response.status === 'finished') {
        await pause(3000);

        await getProfile();

        addNotification(`mfaNotification`, {
          message: 'Multi-factor authentication disabled',
          type: CToastType.Success,
        });
      }
    });

    const { success, data } = await api.delete<null, GenericWorkflowResponse>(
      '/api/authentication/otp',
    );

    if (!success) {
      endLoading('disableMFA');

      addNotification(`mfaNotification`, {
        message: 'Disabling the multi-factor authentication failed',
        type: CToastType.Error,
      });

      return;
    }

    if (data?.reqId) {
      subscribe(data.reqId);
    }
  };

  const enableMFA = () => {
    const route = useRoute();
    const router = useRouter();

    sessionStorage.setItem('entryRouteFullPath', route.fullPath as string);
    sessionStorage.setItem('showModal', 'mfa');

    router.push({ name: 'Login' });
  };

  const isMFAEnabled = computed(() => profile.value?.CSCUserOTPSec === 'true');

  const linkLoginMethod = async () => {
    if (!userToken.value) return;

    startLoading('linkLoginMethod');

    const { subscribe } = useSocket<RegistrationWSResponse>((response) => {
      if (response.status === 'finished') {
        addNotification(`loginMethodNotification`, {
          message: 'Login method successfully added',
          type: CToastType.Success,
        });

        sessionStorage.removeItem('linkLoginMethod');

        getProfile();

        endLoading('linkLoginMethod');
      }
    });

    const acr = sessionStorage.getItem('linkLoginMethod') || '';

    const { success, data } = await api.post<
      AccoutLinkingBody,
      GenericWorkflowResponse
    >('/api/profile/link-account', {
      userToken: userToken.value,
      acr,
    });

    if (!success) {
      endLoading('linkLoginMethod');
      sessionStorage.removeItem('linkLoginMethod');
    }

    if (data.reqId) {
      subscribe(data.reqId);
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    startLoading('changePassword');

    const response = await api.post<ChangePasswordBody, ChangePasswordResponse>(
      '/api/authentication/password/change',
      {
        newPassword,
        oldPassword,
      },
    );

    endLoading('changePassword');

    if (!response.success) {
      return {
        success: false,
        message: getLDAPErrorMessage(
          response.data?.code ?? response.data?.reason ?? '',
        ),
      };
    }

    addNotification(`changePasswordNotification`, {
      message: 'Password changed successfully',
      type: CToastType.Success,
    });

    return { success: true, message: 'Password changed successfully' };
  };

  const getIdentificationSession = async (sessionId: string) => {
    startLoading('getIdentificationSession');

    const response = await api.get<LoaWorkflowResponse>(
      `/api/profile/loa/${sessionId}`,
    );

    endLoading('getIdentificationSession');

    return response;
  };

  const deleteIdentificationSession = async (sessionId: string) => {
    startLoading('deleteIdentificationSession');

    const response = await api.delete<null, { success: boolean }>(
      `/api/profile/loa/${sessionId}`,
    );

    endLoading('deleteIdentificationSession');

    return response;
  };

  /**
   * Start an identification session and return an url which contains the QR code for the mobile app
   */
  const createIdentificationSession = async () => {
    startLoading('loaRaise');

    const response = await api.post<null, LoaRaiseResponse>('/api/profile/loa');

    endLoading('loaRaise');

    return response;
  };

  const saveSshKeys = async (payload: SshKeyPayload) => {
    startLoading('saveSshKeys');

    const { success } = await api.post<SshKeyPayload, { success: boolean }>(
      '/api/profile/ssh-keys',
      payload,
    );

    if (!success) {
      await getProfile();

      endLoading('saveSshKeys');

      return;
    }

    await pause(3000);

    await getProfile();

    addNotification(`sshKeyNotification`, {
      persistent: true,
      title: 'Your updated SSH keys will be active shortly',
      message: 'Please note: A brief delay is expected during this update.',
      type: CToastType.Success,
    });

    endLoading('saveSshKeys');
  };

  /**
   * A helper method to check if user has the required roles
   *
   * @param requiredRoles a flat array of required roles
   *
   * @returns boolean
   */
  const hasRequiredRoles = (requiredRoles: Role[]) =>
    requiredRoles.every((role) =>
      profile.value?.permissions?.roles?.includes(role),
    );

  /**
   * A helper method to check if user has any of the required roles
   *
   * @param requiredRoles a flat array of required roles
   *
   * @returns boolean
   */
  const hasAnyRequiredRole = (requiredRoles: Role[]) => {
    return requiredRoles.some((role) =>
      profile.value?.permissions?.roles?.includes(role),
    );
  };

  const customerRegistration = async (payload: NonHakaRegistrationBody) => {
    startLoading('customerRegistration');

    const response = await api.post<
      NonHakaRegistrationBody,
      GenericWorkflowResponse
    >('/api/authentication/registration/non-haka', payload);

    endLoading('customerRegistration');

    return response;
  };

  const hakaRegistration = async (payload: HakaRegistrationBody) => {
    startLoading('hakaRegistration');

    const { subscribe } = useSocket<RegistrationWSResponse>(
      async (response) => {
        if (response.status === 'finished') {
          await pause(9000);
          localStorage.setItem(
            `registration-completed-${
              userInfo.value.email ||
              userInfo.value?.family_name?.replace(/\s/g, '')
            }`,
            Date.now().toString(),
          );
          endLoading('hakaRegistration');
        }
      },
    );

    const response = await api.post<
      HakaRegistrationBody,
      GenericWorkflowResponse
    >('/api/authentication/registration/haka', payload);

    if (!response.success) {
      endLoading('hakaRegistration');
    }

    if (response.data?.reqId) {
      subscribe(response.data.reqId);
    }

    return response;
  };

  const sendFeedback = async (message: string) => {
    startLoading('sendFeedback');

    const { success } = await api.post<SendFeedbackParams, DataResponse<null>>(
      '/api/message/feedback',
      {
        message,
        email: profile.value.mail,
      },
    );

    if (!success) {
      endLoading('sendFeedback');

      return;
    }

    addNotification(`sshKeyNotification`, {
      title: 'Thank you for your input',
      message: 'Feedback has been successfully sent',
      type: CToastType.Success,
    });

    endLoading('sendFeedback');
  };

  const sendEstimatesToEmail = async (payload: SendEstimatesToEmailPayload) => {
    startLoading('sendEstimatesToEmail');

    const { success } = await api.post<
      SendEstimatesToEmailPayload,
      DataResponse<null>
    >('/api/message/buc', payload);

    if (!success) {
      endLoading('sendEstimatesToEmail');

      return;
    }

    addNotification(`sshKeyNotification`, {
      message: 'The estimates have been successfully sent',
      type: CToastType.Success,
    });

    endLoading('sendEstimatesToEmail');
  };

  const daysUntilLoginExpiration = computed(() => {
    const ends = convertLDAPDate(profile.value.loginExpirationTime);

    const difference = ends.getTime() - new Date().getTime();

    return Math.round(difference / (1000 * 3600 * 24));
  });

  const requestForAccountExtension = () => {
    addDialog(Dialogs.Profile.AccountExtensionRequest);
  };

  const accountExtensionRequests = ref<AccountExtensionRequest[]>([]);

  const getAccountExtensionRequests = async () => {
    const { success, data } = await api.get<
      DataResponse<AccountExtensionRequest[]>
    >('/api/profile/extension-requests');

    if (!success) return;

    accountExtensionRequests.value = data || [];
  };

  const showAccountExtensionRequests = () => {
    addDialog(Dialogs.Profile.AccountExtensionRequestProcessing);
  };

  const processAccountExtensionRequest = async (
    params: ProcessProfileExtensionRequestPayload,
  ) => {
    startLoading(`processAccountExtensionRequest--${params.action}`);

    const response = await api.post<
      ProcessProfileExtensionRequestPayload,
      ProcessAccountExtensionWorkflowResponse
    >('/api/profile/extension-request/process', params);

    await pause(3000);

    await getAccountExtensionRequests();

    endLoading(`processAccountExtensionRequest--${params.action}`);

    if (params.action === 'timeout' && response.success) {
      return;
    }

    if (response.success) {
      addNotification(`extensionNotification`, {
        message: 'Extension request successfully processed',
        type: CToastType.Success,
      });
    } else {
      addNotification(`extensionNotification`, {
        message: response.data.message || 'Unknown error occurred',
        type: CToastType.Error,
        persistent: true,
      });
    }
  };

  const logout = () => {
    const router = useRouter();

    router.push({ name: 'Welcome', query: { logout: 'true' } });
  };

  return {
    accountExtensionRequests,
    daysUntilLoginExpiration,
    mfa,
    profile,
    showLinkingInfo,
    userInfo,
    userToken,
    isMFAEnabled,
    changePassword,
    createIdentificationSession,
    customerRegistration,
    deleteIdentificationSession,
    disableMFA,
    forgotPassword,
    getAccountExtensionRequests,
    getIdentificationSession,
    getMFASeed,
    getProfile,
    getUserInfo,
    hakaRegistration,
    hasAnyRequiredRole,
    hasRequiredRoles,
    linkLoginMethod,
    logout,
    processAccountExtensionRequest,
    requestForAccountExtension,
    resetPassword,
    saveSshKeys,
    sendEstimatesToEmail,
    sendFeedback,
    setUserInfo,
    showAccountExtensionRequests,
    updateProfile,
    updateProfileFields,
    verifyMFAToken,
    enableMFA,
  };
});

type StorageSession = {
  serviceName: string;
  projectNumber: string;
  timestamp: number;
};

type Sessions = Record<string, StorageSession>;

export const useSessionStore = defineComposableStore(
  'session',
  () => {
    const sessions = ref<Sessions>({});

    const createSession = ({
      id,
      state,
    }: {
      id: string;
      state: StorageSession;
    }) => {
      sessions.value[id] = state;
    };

    const getSession = <T = any>(id: string) =>
      computed(() => sessions.value[id] as T);

    const clearExpiredSessions = () => {
      const keys = Object.keys(sessions.value);

      keys.forEach((key) => {
        const expirationTime = sessions.value[key].timestamp;

        if (Date.now() > expirationTime) {
          delete sessions.value[key];
        }
      });
    };

    return {
      sessions,
      clearExpiredSessions,
      createSession,
      getSession,
    };
  },
  { persist: true },
);

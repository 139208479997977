interface SocketParams {
  status: string;
}

type UnsubscribeConditions = Record<string, string | number | boolean>;

const shouldUnsubscribe = <T extends SocketParams>(
  data: T,
  options: UnsubscribeConditions,
) => {
  if (!Object.keys(options).length) return false;

  return Object.entries(options).every(
    ([key, value]) => getDescendantProp(data, key) === value,
  );
};

export const useSocket = <T extends SocketParams>(
  callbackFn: (data: T) => Promise<void>,
  options?: {
    type?: 'WF_COMPLETED' | 'INBOX';
    unsubscribeConditions?: UnsubscribeConditions;
  },
) => {
  if (!options) {
    options = {
      type: 'WF_COMPLETED',
      unsubscribeConditions: {},
    };
  }

  if (!options.type) {
    options.type = 'WF_COMPLETED';
  }

  if (!options.unsubscribeConditions) {
    options.unsubscribeConditions = {};
  }

  const reqID = ref('');

  const config = useRuntimeConfig();

  const url = config.public.messageServiceWss;

  const { data, send } = useWebSocket<string>(url, {
    autoReconnect: {
      retries: 3,
      delay: 1000,
      onFailed() {
        console.log('Failed to connect WebSocket after 3 retries');
      },
    },
  });

  const subscribe = (requestId: string) => {
    reqID.value = requestId;

    send(
      JSON.stringify({
        message: 'subscribe',
        type: `${options.type}:${requestId}`,
      }),
    );
  };

  const unsubscribe = (requestId: string) => {
    send(
      JSON.stringify({
        message: 'unsubscribe',
        type: `${options.type}:${requestId}`,
      }),
    );
  };

  watch(data, async (d) => {
    if (!d) return;

    const response: T = JSON.parse(d);

    await callbackFn({ ...response, requestId: reqID.value });

    if (shouldUnsubscribe(response, options.unsubscribeConditions!)) {
      unsubscribe(reqID.value);
    }
  });

  return { subscribe };
};

import { default as billing_45unit_45calculatorUjiNVi4s22Meta } from "/src/packages/my-csc/layers/billingUnitCalculator/pages/billing-unit-calculator.vue?macro=true";
import { default as buczpCRfStVnAMeta } from "/src/packages/my-csc/layers/billingUnitCalculator/pages/buc.vue?macro=true";
import { default as indexXY76rQfYPIMeta } from "/src/packages/my-csc/layers/contracts/pages/contracts/index.vue?macro=true";
import { default as csc_45customer_45registrationbGrvqKW4GYMeta } from "/src/packages/my-csc/layers/authentication/pages/csc-customer-registration.vue?macro=true";
import { default as dashboardezr4fdWYOtMeta } from "/src/packages/my-csc/layers/dashboard/pages/dashboard.vue?macro=true";
import { default as _91_91token_93_93MSqv780ImWMeta } from "/src/packages/my-csc/layers/authentication/pages/forgot-password/[[token]].vue?macro=true";
import { default as funet_45role_45managementCXIAabiYX1Meta } from "/src/packages/my-csc/layers/organization/pages/funet-role-management.vue?macro=true";
import { default as ida_45adminULUcNhoXEjMeta } from "/src/packages/my-csc/layers/organization/pages/ida-admin.vue?macro=true";
import { default as indexa6gh31iniJMeta } from "/src/packages/my-csc/pages/index.vue?macro=true";
import { default as loginjj0iwAX3NiMeta } from "/src/packages/my-csc/layers/authentication/pages/login.vue?macro=true";
import { default as oidc_45loginDNVNnUYtI0Meta } from "/src/packages/my-csc/layers/authentication/pages/oidc-login.vue?macro=true";
import { default as organization_45reportGesuWj01JKMeta } from "/src/packages/my-csc/layers/organization/pages/organization-report.vue?macro=true";
import { default as profileWo3FYIe9MpMeta } from "/src/packages/my-csc/layers/profile/pages/profile.vue?macro=true";
import { default as project_45presentationsdqjcQ6vGLLMeta } from "/src/packages/my-csc/layers/project/pages/project-presentations.vue?macro=true";
import { default as _91projectNumber_93U2bWmy6B7yMeta } from "/src/packages/my-csc/layers/project/pages/projects/[projectNumber].vue?macro=true";
import { default as createEe8u0ppc69Meta } from "/src/packages/my-csc/layers/project/pages/projects/create.vue?macro=true";
import { default as index6fv5lPXzwlMeta } from "/src/packages/my-csc/layers/project/pages/projects/index.vue?macro=true";
import { default as _91hash_93Te9rWscNMIMeta } from "/src/packages/my-csc/layers/project/pages/projects/invitation/[hash].vue?macro=true";
import { default as registrationfMl5TMdm9UMeta } from "/src/packages/my-csc/layers/authentication/pages/registration.vue?macro=true";
import { default as reportspvPSDqfONMMeta } from "/src/packages/my-csc/layers/report/pages/reports.vue?macro=true";
import { default as token_45managementX3gS9c5fFCMeta } from "/src/packages/my-csc/layers/reppuApi/pages/reppu/token-management.vue?macro=true";
import { default as resource_45application_45managementaPQKA1kg11Meta } from "/src/packages/my-csc/layers/resourceApplication/pages/resource-application-management.vue?macro=true";
import { default as role_45managementVCf0UmcVXlMeta } from "/src/packages/my-csc/layers/organization/pages/role-management.vue?macro=true";
import { default as service_45catalogNzEAA1buObMeta } from "/src/packages/my-csc/layers/service/pages/service-catalog.vue?macro=true";
import { default as userinfolPjpA6hJcpMeta } from "/src/packages/my-csc/layers/profile/pages/userinfo.vue?macro=true";
import { default as welcomeFYEziURQtcMeta } from "/src/packages/my-csc/layers/welcome/pages/welcome.vue?macro=true";
import { default as component_45stubFad7zK70pHMeta } from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFad7zK70pH } from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "BillingUnitCalculator",
    path: "/billing-unit-calculator",
    meta: billing_45unit_45calculatorUjiNVi4s22Meta || {},
    component: () => import("/src/packages/my-csc/layers/billingUnitCalculator/pages/billing-unit-calculator.vue")
  },
  {
    name: "Buc",
    path: "/buc",
    meta: buczpCRfStVnAMeta || {},
    component: () => import("/src/packages/my-csc/layers/billingUnitCalculator/pages/buc.vue")
  },
  {
    name: "Contracts",
    path: "/contracts",
    meta: indexXY76rQfYPIMeta || {},
    component: () => import("/src/packages/my-csc/layers/contracts/pages/contracts/index.vue")
  },
  {
    name: "CustomerRegistration",
    path: "/csc-customer-registration",
    meta: csc_45customer_45registrationbGrvqKW4GYMeta || {},
    component: () => import("/src/packages/my-csc/layers/authentication/pages/csc-customer-registration.vue")
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    meta: dashboardezr4fdWYOtMeta || {},
    component: () => import("/src/packages/my-csc/layers/dashboard/pages/dashboard.vue")
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password/:token?",
    meta: _91_91token_93_93MSqv780ImWMeta || {},
    component: () => import("/src/packages/my-csc/layers/authentication/pages/forgot-password/[[token]].vue")
  },
  {
    name: "FunetRoleManagement",
    path: "/funet-role-management",
    meta: funet_45role_45managementCXIAabiYX1Meta || {},
    component: () => import("/src/packages/my-csc/layers/organization/pages/funet-role-management.vue")
  },
  {
    name: "IdaAdmin",
    path: "/ida-admin",
    meta: ida_45adminULUcNhoXEjMeta || {},
    component: () => import("/src/packages/my-csc/layers/organization/pages/ida-admin.vue")
  },
  {
    name: "Root",
    path: "/",
    meta: indexa6gh31iniJMeta || {},
    component: () => import("/src/packages/my-csc/pages/index.vue")
  },
  {
    name: "Login",
    path: "/login",
    meta: loginjj0iwAX3NiMeta || {},
    component: () => import("/src/packages/my-csc/layers/authentication/pages/login.vue")
  },
  {
    name: "OIDCLogin",
    path: "/oidc-login",
    meta: oidc_45loginDNVNnUYtI0Meta || {},
    component: () => import("/src/packages/my-csc/layers/authentication/pages/oidc-login.vue")
  },
  {
    name: "organization-report",
    path: "/organization-report",
    meta: organization_45reportGesuWj01JKMeta || {},
    component: () => import("/src/packages/my-csc/layers/organization/pages/organization-report.vue")
  },
  {
    name: "Profile",
    path: "/profile",
    meta: profileWo3FYIe9MpMeta || {},
    component: () => import("/src/packages/my-csc/layers/profile/pages/profile.vue")
  },
  {
    name: "project-presentations",
    path: "/project-presentations",
    meta: project_45presentationsdqjcQ6vGLLMeta || {},
    component: () => import("/src/packages/my-csc/layers/project/pages/project-presentations.vue")
  },
  {
    name: "Project",
    path: "/projects/:projectNumber()",
    meta: _91projectNumber_93U2bWmy6B7yMeta || {},
    component: () => import("/src/packages/my-csc/layers/project/pages/projects/[projectNumber].vue")
  },
  {
    name: "ProjectCreation",
    path: "/projects/create",
    meta: createEe8u0ppc69Meta || {},
    component: () => import("/src/packages/my-csc/layers/project/pages/projects/create.vue")
  },
  {
    name: "Projects",
    path: "/projects",
    meta: index6fv5lPXzwlMeta || {},
    component: () => import("/src/packages/my-csc/layers/project/pages/projects/index.vue")
  },
  {
    name: "ProjectInvitation",
    path: "/projects/invitation/:hash()",
    meta: _91hash_93Te9rWscNMIMeta || {},
    component: () => import("/src/packages/my-csc/layers/project/pages/projects/invitation/[hash].vue")
  },
  {
    name: "HakaRegistration",
    path: "/registration",
    meta: registrationfMl5TMdm9UMeta || {},
    component: () => import("/src/packages/my-csc/layers/authentication/pages/registration.vue")
  },
  {
    name: "Reports",
    path: "/reports",
    meta: reportspvPSDqfONMMeta || {},
    component: () => import("/src/packages/my-csc/layers/report/pages/reports.vue")
  },
  {
    name: "ReppuApiManagement",
    path: "/reppu/token-management",
    meta: token_45managementX3gS9c5fFCMeta || {},
    component: () => import("/src/packages/my-csc/layers/reppuApi/pages/reppu/token-management.vue")
  },
  {
    name: "ResourceApplicationManagement",
    path: "/resource-application-management",
    meta: resource_45application_45managementaPQKA1kg11Meta || {},
    component: () => import("/src/packages/my-csc/layers/resourceApplication/pages/resource-application-management.vue")
  },
  {
    name: "RoleManagement",
    path: "/role-management",
    meta: role_45managementVCf0UmcVXlMeta || {},
    component: () => import("/src/packages/my-csc/layers/organization/pages/role-management.vue")
  },
  {
    name: "ServiceCatalog",
    path: "/service-catalog",
    meta: service_45catalogNzEAA1buObMeta || {},
    component: () => import("/src/packages/my-csc/layers/service/pages/service-catalog.vue")
  },
  {
    name: "Userinfo",
    path: "/userinfo",
    meta: userinfolPjpA6hJcpMeta || {},
    component: () => import("/src/packages/my-csc/layers/profile/pages/userinfo.vue")
  },
  {
    name: "Welcome",
    path: "/welcome",
    meta: welcomeFYEziURQtcMeta || {},
    component: () => import("/src/packages/my-csc/layers/welcome/pages/welcome.vue")
  },
  {
    name: component_45stubFad7zK70pHMeta?.name,
    path: "/project-invitation/:pathMatch(.*)",
    component: component_45stubFad7zK70pH
  },
  {
    name: component_45stubFad7zK70pHMeta?.name,
    path: "/myProjects",
    component: component_45stubFad7zK70pH
  },
  {
    name: component_45stubFad7zK70pHMeta?.name,
    path: "/myProjects/project/:pathMatch(.*)",
    component: component_45stubFad7zK70pH
  },
  {
    name: component_45stubFad7zK70pHMeta?.name,
    path: "/forgotPassword/:pathMatch(.*)",
    component: component_45stubFad7zK70pH
  }
]
import { ProjectTypes } from '@/types/enum';
import type { Service } from '@/types/service';

export const serviceNames = {
  allas: 'Allas',
  epouta: 'ePouta',
  mahti: 'Mahti',
  mahti_lustre: 'Mahti Lustre',
  pouta: 'cPouta',
  puhti: 'Puhti',
  puhti_lustre: 'Puhti Lustre',
  rahti: 'Rahti',
  sddesktop: 'SD Desktop',
  taito: 'Taito',
  taitoshell: 'Taito Shell',
  sisu: 'Sisu',
  pukki: 'Pukki',
};

export const lumiServices: Service[] = [
  {
    accordionId: 'lumi-accordion',
    name: 'LUMI',
    description: [
      `A supercomputer with a large number of nodes with accelerators (GPUs). In addition, the system is complemented by a CPU only partition, IaaS cloud services, and a large object storage solution.`,
    ],
    serviceId: 'lumi01',
    serviceName: 'lumi',
    title: 'LUMI',
    subtitle: 'Supercomputer',
    links: ['https://docs.lumi-supercomputer.eu'],
    terms: 'https://lumi-supercomputer.eu/lumi-general-terms-of-use_1-0/',
    loginUrl: 'https://www.lumi.csc.fi',
  },
  {
    accordionId: 'helmi-accordion',
    name: 'HELMI',
    description: ["Finland's first 5-qubit quantum computer"],
    serviceId: 'helmi01',
    serviceName: 'helmi',
    title: 'HELMI',
    subtitle: 'Quantum Computer',
    links: ['https://docs.csc.fi/computing/quantum-computing/overview/#helmi'],
    terms: 'https://lumi-supercomputer.eu/lumi-general-terms-of-use_1-0/',
  },
  {
    accordionId: 'helmi-accordion',
    name: 'HELMI',
    description: ["Finland's first 5-qubit quantum computer"],
    serviceId: 'helmi02',
    serviceName: 'helmi',
    title: 'HELMI',
    subtitle: 'Quantum Computer',
    links: ['https://docs.csc.fi/computing/quantum-computing/overview/#helmi'],
    terms: 'https://lumi-supercomputer.eu/lumi-general-terms-of-use_1-0/',
  },
];

export const legacyServices: Service[] = [
  {
    name: 'Sisu',
    serviceId: 'sisu01',
    title: '',
    subtitle: '',
    description: [],
    accordionId: 'sisu-accordion',
    hasConfigurationEnabled: false,
    captionDescription: 'Sisu',
    serviceName: 'Sisu',
    links: [],
  },
  {
    name: 'Taito',
    serviceId: 'taito01',
    title: '',
    subtitle: '',
    description: [],
    accordionId: 'taito-accordion',
    hasConfigurationEnabled: false,
    captionDescription: 'Taito',
    serviceName: 'Taito',
    links: [],
  },
];

export const services: Service[] = [
  {
    title: 'Allas',
    subtitle: 'Data storage',
    description: [
      'Data management solution for storing and sharing data across the CSC computing infrastructure. This is the best storage option for most data.',
    ],
    accordionId: 'allas-accordion',
    hasConfigurationEnabled: true,
    name: 'Allas',
    captionDescription: 'Allas',
    serviceId: 'allas01',
    serviceName: 'Allas',
    links: ['https://research.csc.fi/-/allas'],
    loginUrl: 'https://allas.csc.fi',
  },
  {
    title: 'cPouta',
    subtitle: 'IaaS cloud',
    description: [
      `Easy to use web interface and a programmable API for managing virtual machines, networks and storage. Starting quota includes 8 instances, 8 cores, 32 GB of memory, 2 floating IPs and 1 TB of storage.`,
    ],
    accordionId: 'cpouta-accordion',
    name: 'cPouta',
    captionDescription: 'CPouta',
    serviceId: 'cpouta01',
    serviceName: 'cpouta',
    terms: 'https://research.csc.fi/pouta-user-policy',
    links: ['https://research.csc.fi/-/cpouta'],
    loginUrl: 'https://pouta.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi, ProjectTypes.Findata],
  },
  {
    title: 'ePouta',
    subtitle: 'IaaS cloud for sensitive data',
    description: [
      'Virtual Private Cloud (VPC) tailored for processing sensitive data. Contact servicedesk@csc.fi to get your project access to ePouta.',
    ],
    accordionId: 'epouta-accordion',
    name: 'ePouta',
    captionDescription: 'EPouta',
    serviceId: 'epouta01',
    serviceName: 'epouta',
    notApplicable: true,
    terms: 'https://research.csc.fi/pouta-user-policy',
    links: ['https://research.csc.fi/-/epouta'],
    excludedInProjectTypes: [
      ProjectTypes.Course,
      ProjectTypes.CourseInternal,
      ProjectTypes.Fmi,
      ProjectTypes.Student,
    ],
  },
  {
    title: 'IDA',
    subtitle: 'Research data storage service',
    description: [
      'IDA provides secure storage for research data. Stored data can be described and published as research datasets via additional services.',
    ],
    academicOrInternal: true,
    hasConfigurationEnabled: true,
    accordionId: 'ida-accordion',
    name: 'IDA',
    captionDescription: 'Ida',
    serviceId: 'ida01',
    serviceName: 'ida',
    links: ['https://www.fairdata.fi/ida/'],
    loginUrl: 'https://ida.fairdata.fi/login',
    terms:
      'https://www.fairdata.fi/en/ida-research-data-storage-service-terms-of-use/',
    excludedInProjectTypes: [
      ProjectTypes.Course,
      ProjectTypes.CourseInternal,
      ProjectTypes.Fmi,
      ProjectTypes.Commercial,
      ProjectTypes.Student,
    ],
  },
  {
    title: 'Kaivos',
    subtitle: 'Database service',
    description: [
      'A deprecated relational database service. Please use the Pukki DBaaS instead.',
    ],
    accordionId: 'kaivos-accordion',
    name: 'Kaivos',
    captionDescription: 'Kaivos',
    serviceId: 'kaivos01',
    serviceName: 'kaivos',
    notApplicable: true,
    links: [],
    excludedInProjectTypes: [
      ProjectTypes.Course,
      ProjectTypes.CourseInternal,
      ProjectTypes.Fmi,
      ProjectTypes.Student,
    ],
  },
  {
    title: 'Mahti',
    subtitle: 'Supercomputer',
    description: [
      'Mahti is a supercomputer designed for massively parallel jobs that require large floating point performance and a capable interconnect.',
    ],
    hasConfigurationEnabled: true,
    accordionId: 'mahti-accordion',
    name: 'Mahti',
    captionDescription: 'Mahti',
    serviceId: 'mahti01',
    serviceName: 'Mahti',
    links: ['https://research.csc.fi/-/mahti'],
    loginUrl: 'https://mahti.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi, ProjectTypes.Student],
  },
  {
    title: 'Puhti',
    subtitle: 'Supercomputer',
    description: [
      'Puhti supercomputer is your gateway to high performance computing (HPC) at CSC. It caters to a wide range of use cases from interactive data analysis to medium scale simulations.',
    ],
    accordionId: 'puhti-fmi-accordion',
    name: 'Puhti-FMI',
    captionDescription: 'PuhtiFmi',
    serviceId: 'puhtifmi01',
    serviceName: 'Puhti-FMI',
    links: ['https://research.csc.fi/-/puhti'],
    loginUrl: 'https://puhti.csc.fi',
    includedOnlyInProjectTypes: [ProjectTypes.Fmi],
  },
  {
    title: 'Puhti',
    subtitle: 'Supercomputer',
    description: [
      'Puhti supercomputer is your gateway to high performance computing (HPC) at CSC. It caters to a wide range of use cases from interactive data analysis to medium scale simulations.',
    ],
    hasConfigurationEnabled: true,
    accordionId: 'puhti-accordion',
    name: 'Puhti',
    captionDescription: 'Puhti',
    serviceId: 'puhti01',
    serviceName: 'Puhti',
    links: ['https://research.csc.fi/-/puhti'],
    loginUrl: 'https://puhti.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi],
  },
  {
    title: 'Rahti',
    subtitle: 'Container cloud',
    description: [
      'A cloud computing service that allows users to host applications and make them accessible over the web. Rahti is based on OKD, which is a distribution of Kubernetes.',
    ],
    accordionId: 'rahti-accordion',
    name: 'Rahti',
    captionDescription: 'Rahti',
    serviceId: 'rahti01',
    serviceName: 'rahti',
    terms: 'https://rahti.csc.fi/agreements/terms_of_use/',
    links: ['https://research.csc.fi/-/rahti'],
    loginUrl: 'https://rahti.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi],
  },
  {
    title: 'Sensitive Data (SD) Connect',
    subtitle: '',
    description: [
      'SD Connect is a service designed for securely storing and sharing sensitive research data. The service automatically encrypts and decrypts your files during upload and download (available via web interface and programmatically). SD Connect uses the Allas cloud storage solution, therefore Allas is automatically activated.',
    ],
    accordionId: 'sd-connect-accordion',
    name: 'SD Connect',
    captionDescription: 'SdConnect',
    serviceId: 'sd-connect01',
    serviceName: 'SD-Connect',
    links: ['https://research.csc.fi/-/sd-connect'],
    loginUrl: 'https://sd-connect.csc.fi',
    excludedInProjectTypes: [
      ProjectTypes.Course,
      ProjectTypes.Fmi,
      ProjectTypes.Student,
    ],
  },
  {
    title: 'Sensitive Data (SD) Desktop',
    subtitle: '',
    description: [
      'SD Desktop is a service for analysing sensitive research data. It provides an isolated, secure private cloud computing environment accessible via a web user interface. All data processing takes place in Finland',
    ],
    accordionId: 'sd-accordion',
    name: 'SD Desktop',
    captionDescription: 'SdDesktop',
    serviceId: 'sd-desktop01',
    serviceName: 'SD-desktop',
    links: ['https://research.csc.fi/-/sd-desktop'],
    loginUrl: 'https://sd-desktop.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi, ProjectTypes.Student],
  },
  {
    title: 'Pukki',
    subtitle: 'Database as a Service',
    description: [
      'Pukki is a Database as a Service (DBaaS), suitable for most database use cases. With Pukki you can easily and effortlessly set up a database with a few clicks and manage it as a self-service, instead of manually setting up and maintaining your own database environment.',
    ],
    accordionId: 'dbaas-accordion',
    name: 'Pukki DBaaS',
    captionDescription: 'DBaaS',
    serviceId: 'dbaas01',
    serviceName: 'DBaaS',
    links: ['https://docs.csc.fi/cloud/dbaas'],
    loginUrl: 'https://pukki.dbaas.csc.fi',
    excludedInProjectTypes: [ProjectTypes.Fmi],
  },
];

export const getServiceNames = () => {
  return [...services, ...lumiServices].reduce(
    (obj, service) => {
      obj[service.serviceId] = service.name;

      return obj;
    },
    {} as Record<string, string>,
  );
};

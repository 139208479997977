import { create, test, enforce, only } from 'vest';

export const serviceActivationSuite = create((data, name?: string) => {
  only(name);

  test('selectedServiceIds', 'Please select at least one service', () => {
    enforce(data.selectedServiceIds).isArray().isNotEmpty();
  });

  test('servicesWithUnacceptedTerms', 'Please accept the terms of use', () => {
    enforce(data.servicesWithUnacceptedTerms).isArray().isEmpty();
  });
});

export enum ResourceUsageType {
  User = 'user',
  Service = 'service',
}

export enum LoaCompletedDialogType {
  Success = 'success',
  Error = 'error',
  Pending = 'pending',
  TimeOut = 'timeout',
}

enum Projects {
  Manager = 'projects.manager',
  Member = 'projects.member',
}

export enum Role {
  OrganizationAdmin = 'organizationAdmin',
  FmiUser = 'fmi',
  IdaManager = 'idaManager',
  KtpoEdu = 'ktpoEdu',
  KtpoResearcher = 'ktpoResearch',
  KtpoStudent = 'ktpoStudent',
  LoaPreviewer = 'loaPreviewer',
  ReportViewer = 'reportViewer',
  ResourceApplicationManager = 'resourceApplicationManager',
  ResourceApplicationViewer = 'resourceApplicationViewer',
  Staff = 'staff',
  CSC = 'csc',
  FunetAdmin = 'funetAdmin',
  ReppuTokenManager = 'reppuTokenManager',
  NoGlobalRole = 'noGlobalRole',
}

enum Organizations {
  ReportViewer = 'organizations.reportViewer',
  IdaManager = 'organizations.idaManager',
  OrganizationAdmin = 'organizations.organizationAdmin',
  FunetAdmin = 'organizations.funetAdmin',
}

export const Permission = {
  Organizations,
  Role,
  Projects,
};

export enum ProjectCreationStatus {
  Success = 200,
  Error = 500,
}

export enum IdentificationStatus {
  Pending = 201,
  Approved = 202,
  Denied = 203,
  NotRequired = 501,
  TimedOut = 502,
}

// Dialogs ----------------------------------------------------------------
enum DevDialogs {
  AddReppuAPIToken = 'LazyAddReppuTokenDialog',
  EditReppuAPIToken = 'LazyEditReppuTokenDialog',
  DisplayReppuAPIToken = 'LazyDisplayReppuTokenDialog',
}

enum RoleDialogs {
  IdleWarning = 'LazyOrganizationRolesIdleDialog',
  Assign = 'LazyOrganizationRolesAssignDialog',
  FunetAssign = 'LazyOrganizationFunetRolesAssignDialog',
}

enum ProjectDialogs {
  Edit = 'LazyProjectDetailsEditDialog',
  Extend = 'LazyProjectManagementExtendDialog',
  Close = 'LazyProjectManagementCloseDialog',
  GDPR = 'LazyProjectGdprFormDialog',
  ServiceQuota = 'LazyServiceQuotaDialog',
  ContentOverview = 'LazyProjectContentOverviewDialog',
  MembersInYear = 'LazyProjectMembersInYearDialog',
  AddPresentation = 'LazyProjectPresentationAddDialog',
  AddFundingDecisions = 'LazyProjectFundingDecisionsAddDialog',
  RemoveFundingDecision = 'LazyProjectFundingDecisionRemoveDialog',
  ShowPresentationInfo = 'LazyProjectPresentationInfoDialog',
  DeletePresentation = 'LazyProjectPresentationDeleteDialog',
}

enum MemberDialogs {
  Invite = 'LazyProjectMemberInviteDialog',
  Management = 'LazyProjectMemberManagementDialog',
  RequestManagement = 'LazyProjectMembershipRequestManagementDialog',
  Add = 'LazyProjectMembersAddDialog',
  Remove = 'LazyProjectMemberRemovalDialog',
  Approve = 'LazyProjectMemberApprovalDialog',
  Deny = 'LazyProjectMemberDenialDialog',
  SearchInfo = 'LazyProjectMembersSearchInfoDialog',
  EmailInfo = 'LazyProjectMembersEmailInfoDialog',
  InvitationInfo = 'LazyProjectMembersInvitationInfoDialog',
}

enum ProfileDialogs {
  EditConfirmation = 'LazyProfileEditConfirmationDialog',
  LOA = 'LazyLevelOfAssuranceDialog',
  LOAComplete = 'LazyLoaCompletedDialog',
  LoginMethodLinking = 'LazyLoginMethodLinkingDialog',
  LoginMethodLinkingConfirmation = 'LazyLoginMethodLinkingConfirmationDialog',
  MFA = 'LazyMultiFactorAuthenticationDialog',
  MFAReLogin = 'LazyMultiFactorAuthenticationReLoginDialog',
  MFADisable = 'LazyMultiFactorAuthenticationDisableDialog',
  PasswordChange = 'LazyPasswordChangeDialog',
  PasswordRequirements = 'LazyPasswordRequirementsDialog',
  SSHKeysAdd = 'LazySshKeysAddDialog',
  SSHKeysDelete = 'LazySshKeysDeleteDialog',
  SSHKeysReLogin = 'LazySshKeysReLoginDialog',
  AccountExtensionRequest = 'LazyAccountExtensionRequestDialog',
  AccountExtensionRequestProcessing = 'LazyAccountExtensionRequestProcessingDialog',
}

enum ResourceApplicationDialogs {
  Default = 'LazyResourceApplicationDialog',
  Internal = 'LazyResourceApplicationInternalDialog',
  Approval = 'LazyResourceApplicationApprovalDialog',
  LumiApproval = 'LazyResourceApplicationLumiApprovalDialog',
  MarkAsFailed = 'LazyResourceApplicationMarkAsFailedDialog',
  ManagementCreate = 'LazyResourceApplicationManagementCreateDialog',
  ManagementCreateLumi = 'LazyResourceApplicationManagementCreateLumiDialog',
}

enum ServiceProfileDialogs {
  AddServices = 'LazyServiceProfileActivationDialog',
  AcceptTerms = 'LazyAcceptTermsDialog',
}

enum GenericDialogs {
  CookieConsent = 'LazyCookieConsentDialog',
  PendingActions = 'LazyPendingActionsDialog',
  Feedback = 'LazyFeedbackDialog',
  IdaQuotaApproval = 'LazyIdaQuotaApprovalDialog',
  MailboxDelete = 'LazyInternalMailboxDeleteDialog',
  MaintenanceBreak = 'LazyMaintenanceBreakDialog',
  Publications = 'LazyPublicationsDialog',
  FundingDecisions = 'LazyFundingDecisionsDialog',
  IdaAdminQuota = 'LazyIdaAdminQuotaDialog',
  Notification = 'LazyGenericNotificationDialog',
  ActiveServicesProjects = 'LazyActiveServicesProjectsDialog',
}

export const Dialogs = {
  ...GenericDialogs,
  Project: ProjectDialogs,
  Profile: ProfileDialogs,
  Member: MemberDialogs,
  ResourceApplication: ResourceApplicationDialogs,
  ServiceProfile: ServiceProfileDialogs,
  Role: RoleDialogs,
  Dev: DevDialogs,
};

export enum ProjectTypes {
  Course = 'Course',
  CourseInternal = 'Course (CSC)',
  Fmi = 'FMI',
  Student = 'Student',
  Academic = 'Academic',
  Findata = 'Academic (Findata)',
  Internal = 'Internal',
  Commercial = 'Commercial',
  Unknown = '-',
}

export enum ProjectCategories {
  Academic = 'Academic',
  Commercial = 'Commercial',
  Findata = 'Findata',
  FMI = 'FMI',
  CSC = 'CSC',
  LUMI = 'LUMI',
  Student = 'Student',
  Course = 'Course',
}

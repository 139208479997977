import validate from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/src/packages/my-csc/middleware/auth.global.ts";
import cookie_45consent_45global from "/src/packages/my-csc/middleware/cookieConsent.global.ts";
import manifest_45route_45rule from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  cookie_45consent_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "manager-status": () => import("/src/packages/my-csc/layers/project/middleware/managerStatus.ts"),
  project: () => import("/src/packages/my-csc/layers/project/middleware/project.ts"),
  projects: () => import("/src/packages/my-csc/layers/project/middleware/projects.ts"),
  country: () => import("/src/packages/my-csc/layers/profile/middleware/country.ts"),
  profile: () => import("/src/packages/my-csc/layers/profile/middleware/profile.ts"),
  "require-mfa": () => import("/src/packages/my-csc/layers/organization/middleware/requireMFA.ts"),
  "handle-project-population": () => import("/src/packages/my-csc/middleware/handleProjectPopulation.ts"),
  root: () => import("/src/packages/my-csc/middleware/root.ts")
}
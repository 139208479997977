import { CToastType } from '@cscfi/csc-ui';
import { gql } from 'graphql-tag';
import type { EditProjectPayload } from '../schemas/updateProject.schema';
import type { ProjectPresentation } from '../schemas/presentations.schema';
import type {
  Project,
  Tag,
  ProjectsResponse,
  CreateLumiProjectPayload,
  CreateProjectWSResponse,
  ProjectInfo,
  ProjectInfoResponse,
  CreateProjectPayload,
  OrganizationDetailsResponse,
  OrganizationDetails,
  MembersByYearResponse,
  YearlyMember,
  ProjectManagerStatus,
  StudentProject,
  CourseProject,
  ProjectFundingDecision,
} from '@/types/project';

import type { DataResponse, GenericWorkflowResponse } from '@/types';
import type { MappingJSONItem } from '@/types/mappingJSON';
import { Dialogs, ProjectCreationStatus } from '@/types/enum';
import type { DataResponseType } from '~/types/response';
import type { PublishProjectPresentationPayload } from '~/layers/resourceApplication/schemas/management.schema';

const getProgressColor = (value: number) => {
  if (value >= 33) {
    return 'var(--c-primary-600)';
  }

  if (value > 10) {
    return 'var(--c-warning-600)';
  }

  return 'var(--c-error-600)';
};

export const useProjectStore = defineComposableStore('project', () => {
  const projectData = ref<Project[]>([]);

  const singleProject = ref<Project>();

  const currentProjectNumber = ref('');

  const tags = ref<Tag[]>([]);

  const createdProjectNumber = ref('');

  const projectInfo = ref<ProjectInfo>();

  const projectOrganization = ref<OrganizationDetails>();

  const projectManagerStatus = ref<ProjectManagerStatus>('ok');

  const mappingJSONError = ref(false);

  const fundingDecisions = ref<ProjectFundingDecision[]>([]);

  watch(
    mappingJSONError,
    (error) => {
      if (!error) return;

      const { addDialog } = useDialogStore();

      addDialog(Dialogs.Notification, false, {
        title: 'Error loading data',
        body: 'A network error occured while loading the data required by the current page. Please try again later.',
      });
    },
    { immediate: true },
  );

  const { addNotification } = useNotificationStore();

  const { profile, getProfile } = useProfileStore();

  const closeProject = async () => {
    const { startLoading } = useLoadingStore();

    startLoading('closeProject');

    const response = await api.put<
      { projectNumber: string },
      GenericWorkflowResponse
    >('/api/project/close', {
      projectNumber: currentProjectNumber.value,
    });

    return response;
  };

  const editProject = async (
    payload: Omit<EditProjectPayload, 'projectNumber'>,
  ) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('editProject');

    const { success } = await api.put<
      EditProjectPayload & { projectNumber: string },
      GenericWorkflowResponse
    >('/api/project', {
      projectNumber: currentProjectNumber.value,
      ...payload,
    });

    if (!success) {
      return;
    }

    await pause(2500);

    await getProjects();

    addNotification('projectEditSuccess', {
      type: CToastType.Success,
      title: 'Project updated',
      message: 'The project has been updated successfully',
    });

    endLoading('editProject');
  };

  const extendProject = async () => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('extendProject');

    const response = await api.put<
      { projectNumber: string },
      GenericWorkflowResponse
    >('/api/project/extend', {
      projectNumber: currentProjectNumber.value,
    });

    endLoading('extendProject');

    return response;
  };

  const getPercentage = (project: Project) => {
    const remaining =
      project.CSCSRPCompPrjAllocatedCompBUs! -
      project.CSCSRPCompPrjUsedCompBUs!;

    const percentage =
      (remaining / (project.CSCSRPCompPrjAllocatedCompBUs || 1)) * 100;

    return Math.max(percentage, 0);
  };

  // TODO:  is this in use? are the fd's already in the projects? Leftover from micro service version? DELETE?
  const getFundingDecisions = async (projectNumber: string) => {
    const { startLoading, endLoading } = useLoadingStore();
    startLoading('getFundingDecisions');

    const { data } = await api.get<DataResponse<ProjectFundingDecision[]>>(
      `/api/project/funding-decisions/${projectNumber}`,
    );

    fundingDecisions.value = data || [];

    endLoading('getFundingDecisions');
  };

  const clearSingleProject = () => {
    singleProject.value = undefined;
  };

  const getProjectManagerStatus = async (projectNumber: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    projectManagerStatus.value = 'ok';

    startLoading('getProjectManagerStatus');

    const { data, success } = await api.get<
      DataResponse<{ status: ProjectManagerStatus }>
    >(`/api/project/manager-status/${projectNumber}`);

    if (!success) {
      endLoading('getProjectManagerStatus');

      return;
    }

    projectManagerStatus.value = data!.status;

    endLoading('getProjectManagerStatus');
  };

  const getProjectByProjectNumber = async (projectNumber: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getProjectByProjectNumber');

    const { data, success } = await api.get<DataResponse<Project>>(
      `/api/project/${projectNumber}`,
    );

    if (!success) {
      endLoading('getProjectByProjectNumber');

      return;
    }

    singleProject.value = parseProjectData(data as Project);

    endLoading('getProjectByProjectNumber');
  };

  const getProjectWithHash = async () => {
    const { startLoading, endLoading } = useLoadingStore();
    const route = useRoute();

    startLoading('getProjectWithHash');

    const { hash } = route.params;

    const { data } = await api.get<ProjectInfoResponse>(
      `/api/project/info/${hash}`,
    );

    if (data) {
      projectInfo.value = {
        ...data.projectInfo,
        alreadyAMember: data.alreadyAMember,
      };
    }

    endLoading('getProjectWithHash');
  };

  const getProjects = async () => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('projects');

    const { data } =
      await api.get<DataResponse<ProjectsResponse>>('/api/project');

    projectData.value = data?.projects || [];

    tags.value = data?.tags || ([] as Tag[]);

    endLoading('projects');
  };

  const getStudentProjects = async (start: string, end: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getStudentProjects');

    const { data } = await api.get<DataResponse<StudentProject[]>>(
      `/api/project/student/${start}/${end}`,
    );

    endLoading('getStudentProjects');

    return data || [];
  };

  const getCourseProjects = async (start: string, end: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getCourseProjects');

    const { data } = await api.get<DataResponse<CourseProject[]>>(
      `/api/project/course/${start}/${end}`,
    );

    endLoading('getCourseProjects');

    return data || [];
  };

  const parseProjectData = (project: Project) => {
    const types = {
      'lumi-okm': 'Lumi',
      internal: 'CSC',
      fmi: 'FMI',
    };

    const isProjectManager =
      !!project?.CSCPrjPriResp &&
      !!profile.value?.dn &&
      project.CSCPrjPriResp === profile.value?.dn;

    const isClosed = project.CSCPrjState?.trim().toLowerCase() === 'closed';

    const isClosingMessage =
      project.CSCPrjState?.trim().toLowerCase() !== 'closed' &&
      !['course', 'ida'].includes(project.CSCPrjType as string)
        ? getProjectIsClosingMessage(project)
        : null;

    const additionalProperties =
      project.CSCPrjType === 'course'
        ? {
            courseLifetime: {
              start: formatLDAPDate(project.CSCPrjCourseStartDate!),
              end: formatLDAPDate(project.CSCPrjCourseEndDate!),
            },
          }
        : {};

    const percentage = getPercentage(project);

    const { e2eTest } = useRuntimeConfig().public;

    const hasBelowZeroBUs =
      e2eTest === 'true'
        ? false
        : project.CSCSRPCompPrjAllocatedCompBUs! -
            project.CSCSRPCompPrjUsedCompBUs! <
          0;

    return {
      ...project,
      CSCPrjState: (project.CSCPrjState || '').toLowerCase().trim(),
      percentage: formatNumber(percentage),
      billingUnits: {
        allocated: formatNumber(project.CSCSRPCompPrjAllocatedCompBUs!),
        remaining: formatNumber(
          project.CSCSRPCompPrjAllocatedCompBUs! -
            project.CSCSRPCompPrjUsedCompBUs!,
        ),
      },
      lifetime: {
        start: formatLDAPDate(project.CSCPrjOpenDate),
        end: formatLDAPDate(
          project.CSCPrjLumiEndDate! || project.CSCPrjClosedDate!,
        ),
      },
      tags: tags.value.filter((tag) => tag.project === project.CSCPrjNum),
      projectManagerCn: getCnFromDn(project.CSCPrjPriResp || ''),
      unixGroup: (project.cn || '').toLowerCase(),
      type:
        types[project.CSCPrjScope as keyof typeof types] ||
        toTitleCase(project.CSCPrjScope),
      isProjectManager,
      hasBelowZeroBUs,
      progressColor: getProgressColor(percentage),
      projectType: getProjectType(project),
      isClosingMessage,
      isClosed,
      fundingDecisions: project.CSCPrjFunderGrantNum
        ? decodeFundingDecisions([project.CSCPrjFunderGrantNum].flat())
        : [],
      ...additionalProperties,
    };
  };

  const projects = computed<Project[]>(() => {
    return projectData.value
      .filter((project) => project.CSCPrjScope?.toLowerCase() !== 'personal')
      .sort((a: Project, b: Project) => {
        if (!a.CSCPrjOpenDate) return 1;

        if (!b.CSCPrjOpenDate) return 0;

        return b.CSCPrjOpenDate.localeCompare(a.CSCPrjOpenDate, 'fi');
      })
      .map(parseProjectData);
  });

  const handleProjectCreationResponse = async (
    response: CreateProjectWSResponse,
  ) => {
    const { endLoading } = useLoadingStore();

    if (response.status !== 'finished') return;

    if (response.data.status !== ProjectCreationStatus.Error) {
      const [, projectNumber] = response.data.projectName!.split('_');

      await pause(2000);

      await getProfile();
      await getReppuToken();

      createdProjectNumber.value = projectNumber;

      addNotification(`projectCreationSuccess`, {
        message: 'The project was successfully created',
        type: CToastType.Success,
      });

      return;
    }

    addNotification('projectCreationError', {
      type: CToastType.Error,
      title: 'Project creation failed',
      message: 'please try again later',
    });

    endLoading('createProject');
  };

  const createProject = async (payload: CreateProjectPayload) => {
    const { startLoading } = useLoadingStore();

    const { subscribe } = useSocket<CreateProjectWSResponse>(
      handleProjectCreationResponse,
    );

    startLoading('createProject');

    const url = `/api/project/${payload.projectType.toLowerCase()}`;

    const { success, data } = await api.post<
      CreateProjectPayload,
      GenericWorkflowResponse
    >(url, payload);

    if (!success) {
      return null;
    }

    if (data.reqId) {
      subscribe(data.reqId);
    }

    return data.reqId;
  };

  const createLumiProject = async (payload: CreateLumiProjectPayload) => {
    const { startLoading } = useLoadingStore();

    const { subscribe } = useSocket<CreateProjectWSResponse>(
      handleProjectCreationResponse,
    );

    startLoading('createLumiProject');

    const { data, success } = await api.post<
      CreateLumiProjectPayload,
      GenericWorkflowResponse
    >('/api/project/lumi', payload);

    if (!success) {
      startLoading('createLumiProject');

      return null;
    }

    if (data.reqId) {
      subscribe(data.reqId);
    }

    return data.reqId;
  };

  const project = computed(() => {
    if (singleProject.value?.CSCPrjNum === currentProjectNumber.value)
      return singleProject.value;

    return (
      projects.value.find((p) => p.CSCPrjNum === currentProjectNumber.value) ||
      ({} as Project)
    );
  });

  watch(project, async () => {
    if (!project.value.CSCPrjHomeOrganization) return;

    const { getOrganization } = useOrganizationStore();

    await getOrganization(getCnFromDn(project.value.CSCPrjHomeOrganization));

    if (project.value.isProjectManager) {
      await getProjectPresentations(project.value.CSCPrjNum);
    }
  });

  const getOrganizationDetails = async (cn: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getOrganizationDetails');

    const { success, data } = await api.get<OrganizationDetailsResponse>(
      `/api/organization/${cn}`,
    );

    if (!success) {
      endLoading('getOrganizationDetails');

      return;
    }

    projectOrganization.value = data;

    endLoading('getOrganizationDetails');
  };

  const getList = async (listName: string) => {
    mappingJSONError.value = false;

    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getList');

    const { data, success } = await api.get<
      DataResponseType<MappingJSONItem[]>
    >(`/api/ldap/json-map?list=${listName}`);

    if (!success) {
      mappingJSONError.value = true;
    }

    endLoading('getList');

    return data || [];
  };

  const getProjectMembersByYear = async (
    projectNumber: string,
    year: string,
    organizationId: string,
  ) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading(`getProjectMembersByYear--${projectNumber}`);

    const { response, query } = useGqlApi();

    const membersByYearQuery = gql`
      query OrganizationProjectUsers(
        $year: String!
        $organizationId: String!
        $projectNumber: String!
      ) {
        organizationProjectUsers(
          year: $year
          organizationId: $organizationId
          projectNumber: $projectNumber
        ) {
          cn
          givenname
          sn
        }
      }
    `;

    await query(membersByYearQuery, {
      projectNumber,
      year,
      organizationId,
    });

    endLoading(`getProjectMembersByYear--${projectNumber}`);

    return response.value
      ? (response.value as MembersByYearResponse).organizationProjectUsers
      : ([] as YearlyMember[]);
  };

  const isAcademicProject = computed(
    () => project.value.CSCPrjScope === 'academic',
  );

  const isCommercialProject = computed(
    () => project.value.CSCPrjScope === 'commercial',
  );

  const isCourseProject = computed(() => project.value.CSCPrjType === 'course');

  const isFindataProject = computed(() =>
    [project.value.CSCPrjType].flat().includes('findata'),
  );

  const isLumiProject = computed(
    () => project.value.CSCPrjScope === 'lumi-okm',
  );

  const isIdaProject = computed(
    () =>
      project.value?.CSCPrjType === 'ida' ||
      project.value?.CSCPrjType?.includes('ida'),
  );

  const isInternalProject = computed(
    () => project.value?.CSCPrjScope === 'internal',
  );

  const isInternalCourseProject = computed(
    () =>
      project.value?.CSCPrjType === 'course' &&
      project.value?.CSCPrjScope === 'internal',
  );

  const isStudentProject = computed(
    () => project.value.CSCPrjType === 'student',
  );

  const isGDPRProject = computed(
    () => project.value?.CSCPrjProcessesPersonalData === 'Yes',
  );

  const projectPresentations = ref<ProjectPresentation[]>([]);

  const publishProjectPresentation = async (
    payload: PublishProjectPresentationPayload,
  ) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading(`publishProjectPresentation_${payload.presentationId}`);

    const { success } = await api.put<
      PublishProjectPresentationPayload,
      DataResponse<null>
    >(`/api/project/presentation`, payload);

    if (success) {
      projectPresentations.value = projectPresentations.value.map((p) => {
        if (p.id === payload.presentationId) {
          p.published = payload.publish;
        }

        return p;
      });
    }

    endLoading(`publishProjectPresentation_${payload.presentationId}`);

    return success;
  };

  const getProjectPresentations = async (projectNumber?: string) => {
    const { startLoading, endLoading } = useLoadingStore();

    startLoading('getProjectPresentations');

    const { data, success } = await api.get<
      DataResponse<ProjectPresentation[]>
    >(
      projectNumber
        ? `/api/project/presentation/${projectNumber}`
        : '/api/project/presentation',
    );

    if (!success) {
      endLoading('getProjectPresentations');

      return;
    }

    projectPresentations.value = data || [];

    endLoading('getProjectPresentations');
  };

  return {
    clearSingleProject,
    closeProject,
    createProject,
    createLumiProject,
    editProject,
    extendProject,
    getProjectByProjectNumber,
    getProjects,
    getCourseProjects,
    getStudentProjects,
    getProjectMembersByYear,
    getProjectWithHash,
    getOrganizationDetails,
    getList,
    getProjectManagerStatus,
    getFundingDecisions,
    getProjectPresentations,
    createdProjectNumber,
    currentProjectNumber,
    project,
    projectData,
    projects,
    projectInfo,
    projectOrganization,
    singleProject,
    projectManagerStatus,
    mappingJSONError,
    fundingDecisions,
    isAcademicProject,
    isCommercialProject,
    isCourseProject,
    isFindataProject,
    isIdaProject,
    isInternalCourseProject,
    isInternalProject,
    isLumiProject,
    isStudentProject,
    isGDPRProject,
    projectPresentations,
    publishProjectPresentation,
  };
});

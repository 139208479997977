import payload_plugin_EWbDaOYKFf from "/src/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_i6qJuXu4kQ from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nd5pBKsIDv from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_k4hgci4TRr from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xrwIwC5jQh from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VhnyVPlwU5 from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YdUaH2P79N from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XEGDWD9lBv from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_5iOceeDmBn from "/src/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.28.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/packages/my-csc/.nuxt/components.plugin.mjs";
import prefetch_client_mMRPMHD4Sj from "/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_dcw75otgnkbyuwjepurxwhft2u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ziYcKwnJdq from "/src/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_Gauyii78TU from "/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.0_typescri_zmjkjardbyfs674g2lzssh3kai/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import control_client_p4yQLeQA58 from "/src/packages/my-csc/plugins/control.client.ts";
import error_ldt3PQauZ0 from "/src/packages/my-csc/plugins/error.ts";
import resetStore_YYP4aTXUos from "/src/packages/my-csc/plugins/resetStore.ts";
import sentry_client_shVUlIjFLk from "/src/packages/my-csc/plugins/sentry.client.ts";
import tooltip_client_Mj8x1saCmV from "/src/packages/my-csc/plugins/tooltip.client.ts";
export default [
  payload_plugin_EWbDaOYKFf,
  revive_payload_client_i6qJuXu4kQ,
  unhead_nd5pBKsIDv,
  router_k4hgci4TRr,
  payload_client_xrwIwC5jQh,
  navigation_repaint_client_VhnyVPlwU5,
  check_outdated_build_client_YdUaH2P79N,
  chunk_reload_client_XEGDWD9lBv,
  plugin_vue3_5iOceeDmBn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mMRPMHD4Sj,
  plugin_ziYcKwnJdq,
  plugin_Gauyii78TU,
  control_client_p4yQLeQA58,
  error_ldt3PQauZ0,
  resetStore_YYP4aTXUos,
  sentry_client_shVUlIjFLk,
  tooltip_client_Mj8x1saCmV
]
import { gql } from 'graphql-tag';
import {
  mdiChip,
  mdiServer,
  mdiNas,
  mdiFileCode,
  mdiHarddisk,
  mdiHarddiskPlus,
} from '@mdi/js';
import type {
  IdaUsage,
  ProjectUsage,
  ProjectUsageItem,
} from '../schemas/projectUsage.schema';
import type {
  MonthlyUsage,
  ParsedStorageUsage,
  ResourceUsage,
  RjrStat,
  Usage,
  UsageItem,
} from '@/types/resourceUsage';

import type {
  StorageUsageItem,
  AllocatedStorageItem,
  BillingUnitsByService,
} from '@/types/project';
import type { DataResponse } from '#build/types/nitro-imports';

export const useResourceUsageStore = defineComposableStore(
  'resourceUsage',
  () => {
    const { startLoading, endLoading, loadErrors } = useLoadingStore();

    const parsedStorageUsage = ref<ParsedStorageUsage[]>([]);

    const usageData = ref<ResourceUsage[]>([]);

    const storageUsage = ref<StorageUsageItem[]>([]);

    const allocatedStorage = ref<AllocatedStorageItem[]>([]);

    const billingUnitsByService = ref<BillingUnitsByService[]>([]);

    const idaUsage = ref<IdaUsage>({} as IdaUsage);

    const adminIdaQuota = ref<IdaUsage>({} as IdaUsage);

    const adminIdaQuotaError = ref(false);

    const statsFetched = ref(false);

    const monthlyUsageByUser = ref<MonthlyUsage>({});

    const config = ref({
      MAHTI01: {
        label: 'Mahti',
        icon: mdiChip,
      },
      MAHTI11: {
        label: 'ProjAppl',
        icon: mdiHarddisk,
      },
      MAHTI12: {
        label: 'Scratch',
        icon: mdiHarddisk,
      },
      MAHTI13: {
        label: 'Large partition',
        icon: mdiHarddiskPlus,
      },
      PUHTI01: {
        label: 'Puhti',
        icon: mdiChip,
      },
      PUHTI11: {
        label: 'ProjAppl',
        icon: mdiHarddisk,
      },
      PUHTI12: {
        label: 'Scratch',
        icon: mdiHarddisk,
      },
      EPOUTA01: {
        label: 'ePouta',
        icon: mdiServer,
      },
      CPOUTA01: {
        label: 'cPouta',
        icon: mdiServer,
      },
      ALLAS01: {
        label: 'Allas',
        icon: mdiNas,
      },
      RAHTI01: {
        label: 'Rahti',
        icon: mdiFileCode,
      },
      IDA01: {
        label: 'IDA',
        icon: mdiNas,
      },
      LUMI01: {
        label: 'LUMI',
        icon: mdiChip,
      },
      LUMI11: {
        label: 'ProjAppl',
        icon: mdiHarddisk,
      },
      LUMI12: {
        label: 'Scratch',
        icon: mdiHarddisk,
      },
      LUMI13: {
        label: 'Fast',
        icon: mdiHarddisk,
      },
    });

    const now = new Date();
    const currentMonth = parseInt(`${now.getMonth()}`, 10);
    const currentYear = parseInt(`${now.getFullYear()}`, 10);

    const totalServiceCumulativeUsage = (month: number) => {
      const monthlyCumulative: Record<string, number> = {};
      let cumulative = 0;

      for (let m = currentMonth + 1; m < 13; m += 1) {
        const totalUsage = usageData.value
          .filter((u) => u.month === m)
          .map((item) => item.bus);

        if (!totalUsage.length) {
          monthlyCumulative[m] = cumulative;
        } else {
          cumulative += totalUsage.reduce((prev, next) => prev + next, 0);
          monthlyCumulative[m] = cumulative;
        }
      }

      if (month <= currentMonth) {
        for (let m = 1; m <= currentMonth; m += 1) {
          const totalUsage = usageData.value
            .filter((u) => u.month === m)
            .map((item) => item.bus);

          cumulative += totalUsage.reduce((prev, next) => prev + next, 0);
          monthlyCumulative[m] = cumulative;
        }
      }

      return monthlyCumulative[month];
    };

    const totalServiceUsage = (month: number) => {
      const totalUsage = usageData.value
        .filter((u) => u.month === month)
        .map((item) => item.bus);

      return !totalUsage.length
        ? 0
        : totalUsage.reduce((prev, next) => prev + next);
    };

    const monthlyServiceUsage = (
      usage: ResourceUsage[],
      service: string,
      isCumulative = false,
    ) => {
      const monthlyUsage = [];

      for (let month = 1; month < 13; month += 1) {
        const monthEntry = usage
          .filter((u) => u.system_name === service && u.month === month)
          .map((item) => item.bus);
        const value = monthEntry.length
          ? monthEntry.reduce((prev, next) => prev + next)
          : 0;

        let year = currentYear.toString();

        if (month > currentMonth + 1) {
          year = (currentYear - 1).toString();
        }

        year = year.toString().substring(2, 4);
        const total = isCumulative
          ? totalServiceCumulativeUsage(month)
          : totalServiceUsage(month);

        monthlyUsage[month - 1] = {
          value,
          total,
          month,
          year,
          service,
        };
      }

      return monthlyUsage;
    };

    const monthlyUserUsage = (
      usage: ResourceUsage[],
      user: string,
      isCumulative = false,
    ) => {
      const monthlyUsage = [];

      for (let month = 1; month < 13; month += 1) {
        const monthEntry = usage
          .filter((u) => u.user_name === user && u.month === month)
          .map((item) => item.bus);

        const value = monthEntry.length
          ? monthEntry.reduce((prev, next) => prev + next)
          : 0;

        let year = currentYear.toString();

        if (month > currentMonth + 1) {
          year = (currentYear - 1).toString();
        }

        year = year.toString().substring(2, 4);

        const total = isCumulative
          ? totalServiceCumulativeUsage(month)
          : totalServiceUsage(month);

        monthlyUsage[month - 1] = {
          value,
          total,
          month,
          year,
          user,
        };
      }

      return monthlyUsage;
    };

    const sortUsage = (usage: Usage[], isCumulative = false) => {
      const sortedArray = [
        ...usage.filter((_, index) => index > currentMonth),
        ...usage.filter((_, index) => index <= currentMonth),
      ];
      let metaValue = 0;

      return sortedArray.map((item) => {
        if (isCumulative) {
          metaValue += item.value;
        } else {
          metaValue = item.value;
        }

        return { total: item.total, value: metaValue };
      });
    };

    const getUsageForServices = (
      users: string[],
      isCumulative = false,
    ): UsageItem[] => {
      const usage = usageData.value.filter((u) => users.includes(u.user_name));

      return servicesWithUsage.value.map((service, index) => {
        const monthlyUsage = monthlyServiceUsage(usage, service, isCumulative);

        return {
          name: service,
          data: sortUsage(monthlyUsage, isCumulative),
          index,
        };
      });
    };

    const getUsageForUsers = (
      services: string[],
      isCumulative = false,
    ): UsageItem[] => {
      const usage = usageData.value.filter((u) =>
        services.includes(u.system_name),
      );

      return usersWithUsage.value.map((user, index) => {
        const monthlyUsage = monthlyUserUsage(usage, user, isCumulative);

        return {
          name: user,
          data: sortUsage(monthlyUsage, isCumulative),
          index,
        };
      });
    };

    const servicesWithUsage = computed(() => {
      const usage = usageData.value.reduce((items, item) => {
        if (item.bus > 0) {
          items.add(item.system_name);
        }

        return items;
      }, new Set<string>());

      return Array.from(usage);
    });

    const usersWithUsage = computed(() => {
      const data = usageData.value.map((item) => ({
        ...item,
        userName: item.user_name,
      }));

      const usage = data.reduce((items, item) => {
        if (item.bus > 0) {
          items.add(item.userName);
        }

        return items;
      }, new Set<string>());

      return Array.from(usage);
    });

    const getProjectStats = async (
      items: ProjectUsageItem[],
      projectNumber?: string,
      loaderName = 'getProjectStats',
    ) => {
      const { currentProjectNumber } = useProjectStore();

      statsFetched.value = false;
      usageData.value = [];
      storageUsage.value = [];
      parsedStorageUsage.value = [];
      allocatedStorage.value = [];
      billingUnitsByService.value = [];
      idaUsage.value = {} as IdaUsage;

      startLoading(loaderName);

      const url = `${
        projectNumber || currentProjectNumber.value
      }/${items.join('/')}`;

      const { data, success } = await api.get<DataResponse<ProjectUsage>>(
        `/api/project/stats/${url}`,
      );

      if (!success) {
        endLoading(loaderName);

        loadErrors.value.add('getProjectStats');

        return;
      }

      loadErrors.value.delete('getProjectStats');

      if (items.includes('projectUsage')) {
        usageData.value = (data?.totalBU || []).map((usage: ResourceUsage) => ({
          ...usage,
          user_name: usage.user_name === 'unknown' ? 'system' : usage.user_name,
        }));
      }

      if (items.includes('parsedStorageUsage')) {
        parsedStorageUsage.value = data?.parsedStorageUsage || [];
      }

      if (items.includes('idaUsage') && data?.idaUsage) {
        idaUsage.value = data.idaUsage;
      }

      if (items.includes('billingUnitsByService')) {
        billingUnitsByService.value = data?.billingUnitsByService || [];
      }

      statsFetched.value = true;

      endLoading(loaderName);
    };

    const getMonthlyUsage = async () => {
      monthlyUsageByUser.value = {};

      startLoading('getMonthlyUsage');

      const response = await api.get<DataResponse<MonthlyUsage>>(
        '/api/project/stats/monthly-usage',
      );

      endLoading('getMonthlyUsage');

      if (response.success && response.data) {
        monthlyUsageByUser.value = response.data;

        loadErrors.value.delete('getMonthlyUsage');

        return;
      }

      loadErrors.value.add('getMonthlyUsage');
    };

    const getAdminIdaQuota = async (projectNumber: string) => {
      adminIdaQuota.value = {} as IdaUsage;
      adminIdaQuotaError.value = false;

      startLoading(`getAdminIdaQuota-${projectNumber}`);

      const { response, query, error } = useGqlApi<{
        idaUsage: IdaUsage;
      }>();

      const billingUnitStatusQuery = gql`
        query IdaUsage($projectNumber: String!) {
          idaUsage(projectNumber: $projectNumber) {
            id
            cscprjnum
            total_files
            frozen_files
            bytes_used
            bytes_frozen
            user_count
            granted_gb
            granted_extra_gb
            published_datasets
          }
        }
      `;

      await query(billingUnitStatusQuery, {
        projectNumber,
      });

      if (error.value) {
        adminIdaQuotaError.value = true;
      }

      adminIdaQuota.value = response.value.idaUsage;

      endLoading(`getAdminIdaQuota-${projectNumber}`);
    };

    const getBillingUnitStatus = async (projectNumber: string) => {
      startLoading('getBillingUnitStatus');

      const { response, query } = useGqlApi();

      const billingUnitStatusQuery = gql`
        query ($projectNumber: String!) {
          rjrProjectInfo(id: $projectNumber) {
            cscsrpcompprjallocatedcompbus
            cscsrpcompprjusedcompbus
          }
        }
      `;

      await query(billingUnitStatusQuery, {
        projectNumber,
      });

      endLoading('getBillingUnitStatus');

      return response.value
        ? response.value.rjrProjectInfo
        : { rjrProjectInfo: {} };
    };

    const getProjectUsageHistory = async (
      projectNumber: string,
      start: string,
      end: string,
    ): Promise<RjrStat[]> => {
      startLoading('getProjectUsageHistory');

      const { response, query } = useGqlApi();

      const projectUsageHistoryQuery = gql`
        query ($projectNumber: String!, $start: String!, $end: String!) {
          rjrStats(project: $projectNumber, dateStart: $start, dateEnd: $end) {
            usage {
              year
              time
              cpu_billing_units
              gpu_billing_units
              vm_bus
              objectstorage_bus
              volumestorage_bus
              network_bus
            }
            system_name
          }
        }
      `;

      await query(projectUsageHistoryQuery, {
        projectNumber,
        start,
        end,
      });

      endLoading('getProjectUsageHistory');

      return response.value
        ? (response.value.rjrStats as RjrStat[])
        : ([] as RjrStat[]);
    };

    return {
      config,
      idaUsage,
      usageData,
      storageUsage,
      parsedStorageUsage,
      allocatedStorage,
      statsFetched,
      servicesWithUsage,
      usersWithUsage,
      adminIdaQuota,
      adminIdaQuotaError,
      billingUnitsByService,
      monthlyUsageByUser,
      getUsageForUsers,
      getProjectStats,
      getUsageForServices,
      getBillingUnitStatus,
      getProjectUsageHistory,
      getAdminIdaQuota,
      getMonthlyUsage,
    };
  },
);

import { convertLDAPDate } from './convertLDAPDate';
import type { Project } from '@/types/project';

const createMessage = (totalDays: number) => {
  const weeks = Math.floor(totalDays / 7);

  const days = totalDays % 7;

  if (totalDays === 1) {
    return 'The project is closing tomorrow';
  }

  if (totalDays === 0) {
    return 'The project is closing today';
  }

  let message = 'The project is closing in ';

  if (weeks > 0) {
    message += `${weeks} week${weeks > 1 ? 's' : ''}`;
  }

  if (weeks > 0 && days > 0) {
    message += ' and ';
  }

  if (days > 0) {
    message += `${days} day${days > 1 ? 's' : ''}`;
  }

  return message;
};

const getDaysUntilClosure = (closingDate: string) => {
  const ends = convertLDAPDate(closingDate);

  const difference = ends.getTime() - new Date().getTime();

  return Math.round(difference / (1000 * 3600 * 24));
};

export const getProjectIsClosingMessage = (
  project: Project,
  warningLimit = 8 * 7,
) => {
  if (!project?.CSCPrjClosedDate) return '';

  const days = getDaysUntilClosure(project.CSCPrjClosedDate);

  if (days > warningLimit || days < 0) return null;

  return createMessage(days);
};
